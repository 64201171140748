import Section from "../components/Section";
import Container from "../components/Container";
import { Helmet } from "react-helmet";

const Privacy = () => {
	return (
		<>
			<Helmet>
				<title>Privacy | Pokebuilder</title>
			</Helmet>
			<Section className="half-page pt-5 pb-5">
				<Container>
					<h1 className="default-text mb-4">Privacy/Terms</h1>
					<p className="mb-30">
						<strong>Last Updated: January 09, 2023</strong>
					</p>

					<h2 className="text-lg mb-10">1. Introduction</h2>
					<p className="text-sm mb-30">
						Welcome to Pokebuilder ("we," "us," or "our"). This Privacy and
						Terms of Service Statement governs your use of Pokebuilder and
						outlines the terms and conditions concerning the collection,
						storage, and use of your personal information.
					</p>

					<h2 className="text-lg mb-10">2. Information We Collect</h2>
					<p className="text-sm mb-2">
						By using Pokebuilder, you agree to provide certain personal
						information, including but not limited to:
					</p>
					<ul className="text-sm mb-30">
						<li>Email addresses</li>
						<li>Mobile numbers</li>
					</ul>

					<h2 className="text-lg mb-10">3. How We Use Your Information</h2>
					<p className="text-sm mb-2">
						We use the information collected for the following purposes:
					</p>
					<ul className="text-sm mb-30">
						<li>
							<strong>User Authentication:</strong> To verify your identity and
							ensure the security of your account.
						</li>
						<li>
							<strong>Communication:</strong> To send you important updates,
							notifications, and information related to your account and
							Pokebuilder.
						</li>
						<li>
							<strong>Service Improvement:</strong> To analyze usage patterns,
							troubleshoot issues, and enhance the overall functionality and
							user experience of Pokebuilder.
						</li>
					</ul>

					<h2 className="text-lg mb-10">4. Security Measures</h2>
					<p className="text-sm mb-30">
						We prioritize the security of your personal information. Pokebuilder
						employs industry-standard security measures to protect against
						unauthorized access, disclosure, alteration, and destruction of your
						data. This includes encryption, secure data storage, and access
						controls.
					</p>

					<h2 className="text-lg mb-10">5. Third-Party Services</h2>
					<p className="text-sm mb-30">
						Pokebuilder may utilize third-party services for various
						functionalities. These services have their own privacy policies, and
						we recommend reviewing them for a comprehensive understanding of how
						your information is handled by these providers.
					</p>

					<h2 className="text-lg mb-10">6. Consent and Opt-out</h2>
					<p className="text-sm mb-30">
						By using Pokebuilder, you consent to the collection, processing, and
						storage of your personal information as outlined in this Privacy and
						Terms of Service Statement. You have the right to opt-out of certain
						communications and services, as specified in the settings of
						Pokebuilder.
					</p>

					<h2 className="text-lg mb-10">7. Changes to this Statement</h2>
					<p className="text-sm mb-30">
						We reserve the right to update this Privacy and Terms of Service
						Statement periodically. Any changes will be communicated to you
						through Pokebuilder or via email. Your continued use of Pokebuilder
						after such modifications constitutes your acceptance of the revised
						statement.
					</p>

					<h2 className="text-lg mb-10">8. Contact Information</h2>
					<p className="text-sm mb-30">
						If you have any questions or concerns about this Privacy and Terms
						of Service Statement, please contact us at contact@pokebuilder.app.
					</p>

					<p className="text-sm mb-30">
						By using Pokebuilder, you agree to abide by the terms and conditions
						outlined in this Privacy and Terms of Service Statement. Thank you
						for trusting us with your information.
					</p>
				</Container>
			</Section>
		</>
	);
};

export default Privacy;
