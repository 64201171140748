const TabItem = ({ className, href, label, active, func }) => {
	return (
		<li className={`nav-item ${className || ""}`} onClick={func}>
			<a
				data-bs-toggle="tab"
				className={`nav-link ${active ? "active" : ""}`}
				aria-current="page"
				href={href}
			>
				{label}
			</a>
		</li>
	);
};

export default TabItem;
