import { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Loader from "../components/Loader";

const Settings = () => {
	const [user, setUser] = useContext(UserContext);
	const [userProfile, setUserProfile] = useState(null);
	const [loading, setLoading] = useState(true);
	const [actionLoading, setActionLoading] = useState(false);
	const [usernameModal, setUsernameModal] = useState(false);
	const [nameModal, setNameModal] = useState(false);
	const [passwordResetModal, setPasswordResetModal] = useState(false);

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [namePassword, setNamePassword] = useState("");
	const [firstNameError, setFirstNameError] = useState("");
	const [lastNameError, setLastNameError] = useState("");
	const [namePasswordError, setNamePasswordError] = useState("");

	const [username, setUsername] = useState("");
	const [usernamePassword, setUsernamePassword] = useState("");
	const [usernameError, setUsernameError] = useState("");
	const [usernamePasswordError, setUsernamePasswordError] = useState("");

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	try {
		var user_obj = JSON.parse(user);
		var auth_token = user_obj.auth_token;
	} catch {
		var user_obj = null;
		var auth_token = null;
	}

	const closeUsernameModal = () => {
		setUsername("");
		setUsernamePassword("");
		setUsernameError("");
		setUsernamePasswordError("");
		setUsernameModal(false);
	};

	const closeNameModal = () => {
		setFirstName(userProfile?.first_name);
		setLastName(userProfile?.last_name);
		setNamePassword("");
		setFirstNameError("");
		setLastNameError("");
		setNamePasswordError("");
		setNameModal(false);
	};

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"X-Auth-Token": auth_token,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/profile`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setUserProfile(data.profile);
			setFirstName(data.profile.first_name);
			setLastName(data.profile.last_name);
		}
		setLoading(false);
	};

	const changeUsername = async (e) => {
		e.preventDefault();
		setUsernameError("");
		setUsernamePasswordError("");
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				username: username,
				password: namePassword,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/username`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "username") {
				setUsernameError(data.msg);
			} else {
				setUsernamePasswordError(data.msg);
			}
		} else {
			setUsername("");
			setUser(JSON.stringify(data.user_session));
			setUserProfile(data.profile);
			setUsernameModal(false);
			successSnackbar(data.msg);
			getData();
		}
		setUsernamePassword("");
		setActionLoading(false);
	};

	const changeName = async (e) => {
		e.preventDefault();
		setFirstNameError("");
		setLastNameError("");
		setNamePasswordError("");
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				first_name: firstName,
				last_name: lastName,
				password: namePassword,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/me/name`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			if (data.error == "first_name") {
				setFirstNameError(data.msg);
			} else if (data.error == "last_name") {
				setLastNameError(data.msg);
			} else {
				setNamePasswordError(data.msg);
			}
		} else {
			setFirstName("");
			setLastName("");
			setUser(JSON.stringify(data.user_session));
			setUserProfile(data.profile);
			setNameModal(false);
			successSnackbar(data.msg);
			getData();
		}
		setNamePassword("");
		setActionLoading(false);
	};

	const changePassword = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				email: userProfile?.email,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/password/forgot`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data[0].msg);
		} else {
			successSnackbar(data.msg);
			setPasswordResetModal(false);
		}
		setActionLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);
	return (
		<>
			<Helmet>
				<title>Account Settings | Pokebuilder</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : (
				<>
					<div className="full-page pt-5 pb-5 background-light">
						<Container>
							<Row>
								<Col className="col-lg-8 col-12">
									<h2 className="page-header-title text-xl default-text bold-text upper mb-30">
										Account Settings
									</h2>
									<div className="rounded background-white p-30 mb-20">
										<Row>
											<Col className="mb-10">
												<span className="medium-text text-xl default-text">
													Username
												</span>
											</Col>
										</Row>
										<Row>
											<Col className="settings-val-container flex-row-left col-lg-8 col-12">
												<span>{userProfile?.username}</span>
											</Col>
											{/* <Col className="settings-action-container flex-row-right col-lg-4 col-12">
												<Button
													className="btn-primary full medium-text"
													onClick={() => setUsernameModal(true)}
												>
													Change Username
												</Button>
											</Col> */}
										</Row>
									</div>
									<div className="rounded background-white p-30 mb-20">
										<Row>
											<Col className="mb-10">
												<span className="medium-text text-xl default-text">
													Email
												</span>
											</Col>
										</Row>
										<Row>
											<Col className="settings-val-container flex-row-left col-lg-8 col-12">
												<span>{userProfile?.email}</span>
											</Col>
											{/* <Col className="flex-row-right col-lg-3 col-12">
												<Button className="btn-primary full medium-text">
													Change Email
												</Button>
											</Col> */}
										</Row>
									</div>
									<div className="rounded background-white p-30 mb-20">
										<Row>
											<Col className="mb-10">
												<span className="medium-text text-xl default-text">
													Display Name
												</span>
											</Col>
										</Row>
										<Row>
											<Col className="settings-val-container flex-row-left col-lg-8 col-12">
												<span>
													{userProfile?.first_name} {userProfile?.last_name}
												</span>
											</Col>
											<Col className="settings-action-container flex-row-right col-lg-4 col-12">
												<Button
													className="btn-primary full medium-text"
													onClick={() => setNameModal(true)}
												>
													Change Name
												</Button>
											</Col>
										</Row>
									</div>
									<div className="rounded background-white p-30 mb-20">
										<Row>
											<Col className="mb-10">
												<span className="medium-text text-xl default-text">
													Password
												</span>
											</Col>
										</Row>
										<Row>
											<Col className="settings-val-container flex-row-left col-lg-8 col-12">
												<span>Change your password here.</span>
											</Col>
											<Col className="settings-action-container flex-row-right col-lg-4 col-12">
												<Button
													className="btn-primary full medium-text"
													onClick={() => setPasswordResetModal(true)}
												>
													Reset Password
												</Button>
											</Col>
										</Row>
									</div>
									{/* <div className="rounded background-white p-30 mb-20">
										<Row>
											<Col className="mb-10">
												<span className="medium-text text-xl default-text">
													Account Management
												</span>
											</Col>
										</Row>
										<Row>
											<Col className="settings-val-container flex-row-left col-lg-8 col-12">
												<span>
													Permanently delete your account and all of your
													content.
												</span>
											</Col>
											<Col className="settings-action-container flex-row-right col-lg-4 col-12">
												<Button variant="danger" className="full medium-text">
													Delete Account
												</Button>
											</Col>
										</Row>
									</div> */}
								</Col>
							</Row>
						</Container>
					</div>
					<Modal
						show={usernameModal}
						className="post-modal"
						onHide={closeUsernameModal}
						centered
					>
						<Modal.Header>
							<Modal.Title className="default-text medium-text title-sm">
								Change Username
							</Modal.Title>
						</Modal.Header>
						<Form onSubmit={changeUsername}>
							<Modal.Body>
								<Row className="mb-4">
									<Col>
										<label className="default-text bold-text mb-1">
											New Username
										</label>
										<Form.Control
											type="text"
											className={`form-input ${usernameError && "error"}`}
											placeholder="Enter new username"
											value={username}
											onChange={(e) => setUsername(e.target.value)}
										/>
										<div className="full align-left">
											<p className="mb-0 mt-2 text-danger text-sm">
												{usernameError && usernameError}
											</p>
										</div>
									</Col>
								</Row>
								<Row className="mb-4">
									<Col>
										<label className="default-text bold-text mb-1">
											Password
										</label>
										<Form.Control
											type="password"
											className={`form-input ${
												usernamePasswordError && "error"
											}`}
											placeholder="Enter your password"
											value={usernamePassword}
											onChange={(e) => setUsernamePassword(e.target.value)}
										/>
										<div className="full align-left">
											{usernamePasswordError ? (
												<p className="mb-0 mt-2 text-danger text-sm">
													{usernamePasswordError}
												</p>
											) : (
												<p className="mb-0 mt-2 text-sm">
													Before changing your username, please enter your
													password.
												</p>
											)}
										</div>
									</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="light"
									className="modal-btn sm medium-text"
									onClick={closeUsernameModal}
								>
									Cancel
								</Button>
								<Button
									type="submit"
									className="modal-btn sm btn-yellow medium-text"
									disabled={actionLoading}
								>
									{actionLoading ? "Saving.." : "Save Changes"}
								</Button>
							</Modal.Footer>
						</Form>
					</Modal>
					<Modal
						show={nameModal}
						className="post-modal"
						onHide={closeNameModal}
						centered
					>
						<Modal.Header>
							<Modal.Title className="default-text medium-text title-sm">
								Change Display Name
							</Modal.Title>
						</Modal.Header>
						<Form onSubmit={changeName}>
							<Modal.Body>
								<Row className="mb-4">
									<Col>
										<label className="default-text bold-text mb-1">
											First Name
										</label>
										<Form.Control
											type="text"
											className={`form-input ${firstNameError && "error"}`}
											placeholder="Enter first name"
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
										<div className="full align-left">
											<p className="mb-0 mt-2 text-danger text-sm">
												{firstNameError && firstNameError}
											</p>
										</div>
									</Col>
									<Col>
										<label className="default-text bold-text mb-1">
											Last Name
										</label>
										<Form.Control
											type="text"
											className={`form-input ${lastNameError && "error"}`}
											placeholder="Enter last name"
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
										/>
										<div className="full align-left">
											<p className="mb-0 mt-2 text-danger text-sm">
												{lastNameError && lastNameError}
											</p>
										</div>
									</Col>
								</Row>
								<Row className="mb-4">
									<Col>
										<label className="default-text bold-text mb-1">
											Password
										</label>
										<Form.Control
											type="password"
											className={`form-input ${namePasswordError && "error"}`}
											placeholder="Enter your password"
											value={namePassword}
											onChange={(e) => setNamePassword(e.target.value)}
										/>
										<div className="full align-left">
											{namePasswordError ? (
												<p className="mb-0 mt-2 text-danger text-sm">
													{namePasswordError}
												</p>
											) : (
												<p className="mb-0 mt-2 text-sm">
													Before changing your username, please enter your
													password.
												</p>
											)}
										</div>
									</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="light"
									className="modal-btn sm medium-text"
									onClick={closeNameModal}
								>
									Cancel
								</Button>
								<Button
									type="submit"
									className="modal-btn sm btn-yellow medium-text"
									disabled={actionLoading}
								>
									{actionLoading ? "Saving.." : "Save Changes"}
								</Button>
							</Modal.Footer>
						</Form>
					</Modal>
					<Modal
						show={passwordResetModal}
						onHide={() => setPasswordResetModal(false)}
						centered
					>
						<Modal.Body>
							<h2 className="medium-text text-xl default-text">
								Reset Password
							</h2>
							<p className="mb-0">
								We'll send a password reset link to {userProfile?.email}, would
								you like to proceed?
							</p>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="light"
								className="modal-btn sm medium-text"
								onClick={() => setPasswordResetModal(false)}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								className="modal-btn sm btn-yellow medium-text"
								onClick={changePassword}
								disabled={actionLoading}
							>
								{actionLoading ? "Sending.." : "Send Reset Link"}
							</Button>
						</Modal.Footer>
					</Modal>
				</>
			)}
		</>
	);
};

export default Settings;
