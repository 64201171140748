import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Section from "../components/Section";
import notFound from "../assets/404.png";
import Button from "../components/Button";

const NoPage = () => {
	return (
		<>
			<Helmet>
				<title>Page Not Found | Pokebuilder</title>
			</Helmet>
			<Section className="half-page flex-column pt-5 pb-5">
				<img
					src={notFound}
					alt="Page not found graphics"
					className="nopage-img"
				/>
				<h1 className="default-text bold-text mt-3">A sleeping Snorlax.</h1>
				<p className="default-text mt-2">
					We couldn't find the page you're looking for :(
				</p>
				<Link to="/" className="mt-3">
					<Button className="btn-primary">Back to home</Button>
				</Link>
			</Section>
		</>
	);
};

export default NoPage;
