import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebookF,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Helmet } from "react-helmet";

import Section from "../components/Section";
import Container from "../components/Container";

const Contact = () => {
	return (
		<>
			<Helmet>
				<title>Contact Us | Pokebuilder</title>
			</Helmet>
			<Section className="half-page pt-5 pb-5">
				<Container>
					<h1 className="default-text mb-4">Contact Us</h1>
					<p className="about-body default-text">
						To get in touch with us, your can either message through any of the
						links below, or email us at{" "}
						<a
							href="mailto:contact@pokebuilder.com"
							className="link-text bold-text"
						>
							contact@pokebuilder.com
						</a>
						.
					</p>
					<div className="flex-row-left">
						<a href="https://facebook.com" className="contact-social">
							<FontAwesomeIcon icon={faFacebookF} className="default-text" />
						</a>
						<a href="https://twitter.com" className="contact-social ms-5">
							<FontAwesomeIcon icon={faTwitter} className="default-text" />
						</a>
						<a href="https://instagram.com" className="contact-social ms-5">
							<FontAwesomeIcon icon={faInstagram} className="default-text" />
						</a>
					</div>
				</Container>
			</Section>
		</>
	);
};

export default Contact;
