import React, { createContext, useEffect, useState } from "react";

export const ClipboardContext = createContext();

export const ClipboardProvider = (props) => {
    const [clipboard, setClipboard] = useState(JSON.parse(localStorage.getItem("pokebuilderClipboard")) || []);

    useEffect(() => {
        const fetchClipboard = async () => {
            localStorage.setItem("pokebuilderClipboard", JSON.stringify(clipboard));
        };
        fetchClipboard();
    }, [clipboard])

    return (
        <ClipboardContext.Provider value={[clipboard, setClipboard]}>
            { props.children }
        </ClipboardContext.Provider>
    )
};