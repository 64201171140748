import empty from "../assets/empty.png";

const EmptyState = ({ className }) => {
	return (
		<div className={`empty-state flex-column ${className || ""}`}>
			<img src={empty} alt="Empty page graphics" className="mb-4" />
			<span className="default-text">Nothing to display here.</span>
		</div>
	);
};

export default EmptyState;
