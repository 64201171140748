const Tiles = ({ className }) => {
	return (
		<svg
			className={className}
			width="19"
			height="18"
			viewBox="0 0 19 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.25 8.5H3.25C1.73437 8.5 0.5 7.26563 0.5 5.75V2.75C0.5 1.23437 1.73437 0 3.25 0H6.25C7.76563 0 9 1.23437 9 2.75V5.75C9 7.26563 7.76563 8.5 6.25 8.5Z"
				fill="#392156"
			/>
			<path
				d="M15.75 7.75H12.75C11.6458 7.75 10.75 6.85417 10.75 5.75V2.75C10.75 1.64583 11.6458 0.75 12.75 0.75H15.75C16.8542 0.75 17.75 1.64583 17.75 2.75V5.75C17.75 6.85417 16.8542 7.75 15.75 7.75Z"
				stroke="#392156"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.25 17.25H3.25C2.14583 17.25 1.25 16.3542 1.25 15.25V12.25C1.25 11.1458 2.14583 10.25 3.25 10.25H6.25C7.35417 10.25 8.25 11.1458 8.25 12.25V15.25C8.25 16.3542 7.35417 17.25 6.25 17.25Z"
				stroke="#392156"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.75 18H12.75C11.2344 18 10 16.7656 10 15.25V12.25C10 10.7344 11.2344 9.5 12.75 9.5H15.75C17.2656 9.5 18.5 10.7344 18.5 12.25V15.25C18.5 16.7656 17.2656 18 15.75 18Z"
				fill="#392156"
			/>
		</svg>
	);
};

export default Tiles;
