import React, { useContext, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { NotifContext } from "../context/NotifContext";
import { useSnackbar } from "react-simple-snackbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Clipboard from "../components/Clipboard";
import Footer from "../components/Footer";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import logo from "../assets/logo.png";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Tiles from "../components/Tiles";
import Notification from "../components/Notification";
import { faBell } from "@fortawesome/free-regular-svg-icons";

const Layout = () => {
	const [user, setUser] = useContext(UserContext);
	const [notifCount] = useContext(NotifContext);
	const [showNav, setShowNav] = useState(false);

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	try {
		var user_obj = JSON.parse(user);
	} catch {
		var user_obj = null;
	}

	const ProfileToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			href=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="header-user-dropdown text-sm flex-row-left"
		>
			{children}
			<i className="ri-arrow-drop-down-line header-user-icon" />
		</a>
	));

	const logout = async (e) => {
		e.preventDefault();
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user_obj.auth_token,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/logout`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setUser(null);
		}
	};

	return (
		<>
			<Notification />
			<div className="background-default main-nav flex-row-left">
				<Container className="container background-default flex-row-left">
					<a href="/" className="nav-brand">
						<img src={logo} alt="Pokebuilder logo" />
					</a>
					<div className="nav-lg flex-row-left full">
						<Nav.Link className="medium-text white-text upper" href="/decks/">
							Decks
						</Nav.Link>
						<Nav.Link className="medium-text white-text upper" href="/cards/">
							Cards
						</Nav.Link>
						<Nav.Link className="medium-text white-text upper" href="/sets/">
							Sets
						</Nav.Link>
						<div className="flex-row-right full">
							<Link to="/builder">
								<Button className="header-btn rounded flex-row-center btn-yellow upper default-text medium-text">
									<Tiles className="btn-tiles" />
									Deck Builder
								</Button>
							</Link>
							{!user_obj ? (
								<>
									<a href="/login">
										<Button className="header-btn header-login-btn rounded flex-row-center btn-purple upper default-text medium-text">
											Login
										</Button>
									</a>
									<a href="/signup">
										<Button className="header-btn header-login-btn rounded flex-row-center btn-purple upper default-text medium-text">
											Signup
										</Button>
									</a>
								</>
							) : (
								<>
									<Link to="/notifications">
										<Button className="notification-btn flex-column">
											<FontAwesomeIcon icon={faBell} className="white-text" />
											{notifCount ? (
												<span className="notif-counter bg-danger flex-column">
													{notifCount > 9 ? "9+" : notifCount}
												</span>
											) : (
												""
											)}
										</Button>
									</Link>
									<Dropdown align="end">
										<Dropdown.Toggle as={ProfileToggle}>
											<div className="header-user-img flex-column">
												<span className="text-white upper bold-text text-md">
													{user_obj?.username[0]}
												</span>
											</div>
										</Dropdown.Toggle>
										<Dropdown.Menu className="account-dropdown dropdown-default">
											<Dropdown.Item href="/builder" className="text-sm">
												My Decks
											</Dropdown.Item>
											<Dropdown.Item href="/settings" className="text-sm">
												Account Settings
											</Dropdown.Item>
											<Dropdown.Divider />
											<Dropdown.Item
												href="/logout"
												className="text-sm"
												onClick={logout}
											>
												Logout
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</>
							)}
						</div>
					</div>
					<div className="nav-sm flex-row-right full">
						<div
							className="nav-toggle flex-column"
							onClick={() => setShowNav(!showNav)}
						>
							<div className={`fries ${showNav ? "active" : ""}`}>
								<div className="top-fry" />
								<div className="mid-fry" />
								<div className="bottom-fry" />
							</div>
						</div>
					</div>
				</Container>
			</div>
			<div className={`nav-panel background-default ${showNav && "active"}`}>
				<Nav.Link className="white-text upper" href="/decks/">
					Decks
				</Nav.Link>
				<Nav.Link className="white-text" href="/cards/">
					Cards
				</Nav.Link>
				<Nav.Link className="white-text upper" href="/sets/">
					Sets
				</Nav.Link>
				<div className="nav-btn-container full">
					<Link to="/builder">
						<Button className="nav-btn full rounded flex-row-center btn-yellow upper default-text medium-text">
							<Tiles className="btn-tiles" />
							Deck Builder
						</Button>
					</Link>
					{user_obj ? (
						<>
							<a href="/settings">
								<Button className="nav-btn full rounded flex-row-center btn-purple upper default-text medium-text">
									Account Settings
								</Button>
							</a>
							<Button
								className="nav-btn full rounded flex-row-center btn-purple upper default-text medium-text"
								onClick={logout}
							>
								Logout
							</Button>
						</>
					) : (
						<>
							<a href="/login">
								<Button className="nav-btn full rounded flex-row-center btn-purple upper default-text medium-text">
									Login
								</Button>
							</a>
							<a href="/signup">
								<Button className="nav-btn full rounded flex-row-center btn-purple upper default-text medium-text">
									Signup
								</Button>
							</a>
						</>
					)}
				</div>
			</div>
			<Outlet />
			<Clipboard />
			<Footer />
		</>
	);
};

export default Layout;
