import { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { NotifContext } from "../context/NotifContext";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
	apiKey: process.env.REACT_APP_FCM_API_KEY,
	authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FCM_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FCM_SENDER_ID,
	appId: process.env.REACT_APP_FCM_APP_ID,
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const RequestForToken = () => {
	const [user] = useContext(UserContext);
	const [token, setToken] = useState(null);

	try {
		var user_obj = JSON.parse(user);
	} catch {
		var user_obj = null;
	}

	const saveToken = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": user_obj.auth_token,
			},
			body: JSON.stringify({
				token: token,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/device-tokens`,
			requestOptions
		);

		if (!response.ok) {
			console.log("Could not save token.");
		}
	};

	useEffect(() => {
		if (user && token) {
			saveToken();
		}
	}, [user, token]);

	return getToken(messaging, {
		vapidKey:
			"BMxMielMhNDPSihk5l58DEflU2njUJzXHfIPXok8qPfnXBFnUVgYTWeDGzkutmkLD59wX0iBGZCIMBgUCngUEDY",
	})
		.then((currentToken) => {
			if (currentToken) {
				setToken(currentToken);
			} else {
				// Show permission request UI
				console.log(
					"No registration token available. Request permission to generate one."
				);
			}
		})
		.catch((err) => {
			console.log("An error occurred while retrieving token. ", err);
		});
};

export const onMessageListener = () =>
	new Promise((resolve) => {
		const [notifCount, setNotifCount] = useContext(NotifContext);

		onMessage(messaging, (payload) => {
			resolve(payload);
			setNotifCount(notifCount + 1);
		});
	});
