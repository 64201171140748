import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

import CardPlaceholder from "./CardPlaceholder";
import Column from "./Column";
import ReactTooltip from "react-tooltip";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

const Card = ({ card, className, func, type }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleClick = (card, count) => {
		if (func.name === "copyCard") {
			func(card, count);
		} else {
			setLoading(true);
			func(card).then(() => setLoading(false));
		}
	};

	return (
		<>
			<ReactTooltip />
			<Column key={card.id} className={`col-6 ${className}`}>
				<div className="card-item mt-3 mb-3 rounded background-light">
					{loading && (
						<div className="card-loader flex-column">
							<Spinner className="default-text" />
						</div>
					)}
					{!isLoaded ? <CardPlaceholder /> : ""}
					{type === "add" ? (
						<a
							href="#"
							onClick={() => handleClick(card, 1)}
							className="card-link"
							data-html={true}
							data-effect="solid"
							data-place="right"
							data-class="card-tooltip"
							data-type="light"
							data-delay-show="800"
							data-tip={`
					<img src=${card.lg_img} alt="Card photo" className="tooltip-img"><br /><br />
					<span><strong>Format: </strong>${card.card_format}</span><br />
					<span><strong>Set: </strong>${card.card_set && card.card_set.name} <img src=${
								card.card_set && card.card_set.symbol
							} alt="Set logo" /></span><br />
					<span><strong>Illustrator: </strong>${card.artist && card.artist}</span><br />
				`}
						>
							<img
								src={card.lg_img}
								alt="Card photo"
								className={`card-item-img full ${!isLoaded ? "hidden" : ""}`}
								onLoad={() => setIsLoaded(true)}
							/>
						</a>
					) : (
						<>
							<Link
								to={`/cards/${card.id}`}
								className="card-link"
								data-html={true}
								data-effect="solid"
								data-place="right"
								data-class="card-tooltip"
								data-type="light"
								data-delay-show="800"
								data-tip={`
                        <img src=${
													card.lg_img
												} alt="Card photo" className="tooltip-img"><br /><br />
                        <span><strong>Format: </strong>${
													card.card_format
												}</span><br />
                        <span><strong>Set: </strong>${
													card.card_set && card.card_set.name
												} <img src=${
									card.card_set && card.card_set.symbol
								} alt="Set logo" /></span><br />
                        <span><strong>Illustrator: </strong>${
													card.artist && card.artist
												}</span><br />
                    `}
							>
								<img
									src={card.lg_img}
									alt="Card photo"
									className={`card-item-img full ${!isLoaded ? "hidden" : ""}`}
									onLoad={() => setIsLoaded(true)}
								/>
							</Link>
							<div className="card-btn-container background-light">
								<Button
									className="btn-yellow upper full medium-text mb-2 text-sm"
									onClick={() => handleClick(card, 1)}
								>
									Add 1 to Clipboard
								</Button>
								<Button
									className="upper full medium-text text-sm"
									onClick={() => handleClick(card, 4)}
								>
									Add 4 to Clipboard
								</Button>
							</div>
						</>
					)}
				</div>
			</Column>
		</>
	);
};

export default Card;
