import { useState, useContext } from "react";
import { ClipboardContext } from "../context/ClipboardContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronUp,
	faMinus,
	faPlus,
	faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useSnackbar } from "react-simple-snackbar";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Copy from "./Copy";

const Clipboard = () => {
	const [clipboard, setClipboard] = useContext(ClipboardContext);
	const [preview, setPreview] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [defaultSnackbar] = useSnackbar();

	const toggleClipboard = () => {
		if (isActive) {
			setIsActive(false);
		} else {
			setIsActive(true);
		}
	};

	const copyCard = (card) => {
		if (clipboard.filter((existing) => existing.id == card.id).length != 0) {
			const newClipboard = [...clipboard];
			newClipboard.filter((existing) => existing.id == card.id)[0].qty += 1;
			setClipboard(newClipboard);
		} else {
			setClipboard((clipboard) => [
				...clipboard,
				{
					index: clipboard.length,
					id: card.id,
					tcg_id: card.tcg_id,
					number: card.number,
					ptcgo_code: card.card_set.ptcgo_code,
					img: card.lg_img,
					name: card.name,
					qty: 1,
				},
			]);
		}
	};

	const uncopyCard = (card) => {
		if (clipboard.filter((existing) => existing.id == card.id)[0].qty > 1) {
			const newClipboard = [...clipboard];
			newClipboard.filter((existing) => existing.id == card.id)[0].qty -= 1;
			setClipboard(newClipboard);
		} else {
			setClipboard(clipboard.filter((existing) => existing.id !== card.id));
		}
	};

	const showPreview = (img) => {
		setPreview(img);
		console.log(preview);
	};

	const copyToClipboard = () => {
		var pokemonString = "";
		var trainerString = "";
		var energyString = "";
		clipboard
			.filter((clipboardCard) => clipboardCard.supertype == "Pokémon")
			.map((card) =>
				pokemonString == ""
					? (pokemonString =
							"Pokémon (" +
							clipboard
								.filter((clipboardCard) => clipboardCard.supertype == "Pokémon")
								.reduce((prev, next) => prev + next.qty, 0) +
							")\n" +
							card.qty +
							" " +
							card.name +
							" " +
							card.ptcgo_code +
							" " +
							card.number)
					: (pokemonString +=
							"\n" +
							card.qty +
							" " +
							card.name +
							" " +
							card.ptcgo_code +
							" " +
							card.number)
			);

		clipboard
			.filter((clipboardCard) => clipboardCard.supertype == "Trainer")
			.map((card) =>
				trainerString == ""
					? (trainerString =
							"Trainer (" +
							clipboard
								.filter((clipboardCard) => clipboardCard.supertype == "Trainer")
								.reduce((prev, next) => prev + next.qty, 0) +
							")\n" +
							card.qty +
							" " +
							card.name +
							" " +
							card.ptcgo_code +
							" " +
							card.number)
					: (trainerString +=
							"\n" +
							card.qty +
							" " +
							card.name +
							" " +
							card.ptcgo_code +
							" " +
							card.number)
			);

		clipboard
			.filter((clipboardCard) => clipboardCard.supertype == "Energy")
			.map((card) =>
				energyString == ""
					? (energyString =
							"Energy (" +
							clipboard
								.filter((clipboardCard) => clipboardCard.supertype == "Energy")
								.reduce((prev, next) => prev + next.qty, 0) +
							")\n" +
							card.qty +
							" " +
							card.name +
							" " +
							card.ptcgo_code +
							" " +
							card.number)
					: (energyString +=
							"\n" +
							card.qty +
							" " +
							card.name +
							" " +
							card.ptcgo_code +
							" " +
							card.number)
			);

		var copyString =
			(pokemonString != "" ? pokemonString + "\n\n" : "") +
			(trainerString != "" ? trainerString + "\n\n" : "") +
			(energyString != "" ? energyString + "\n\n" : "");

		navigator.clipboard.writeText(copyString);
		defaultSnackbar("Copied to clipboard.");
	};

	const clearClipboard = () => {
		setClipboard([]);
		setPreview(null);
	};

	return (
		<>
			<div
				className={`clipboard-backdrop ${isActive ? "active" : ""}`}
				onClick={toggleClipboard}
			></div>
			<div className={`clipboard ${isActive ? "active" : ""}`}>
				<Container className="clipboard-header-container">
					<div className="clipboard-header">
						<div
							className="clipboard-toggle background-default flex-row-center pull-right"
							onClick={toggleClipboard}
						>
							<Copy className="clipboard-toggle-copy" fill="#3a2156" />
							<span className="default-text bold-text upper">
								Card Clipboard (
								{clipboard.reduce((prev, next) => prev + next.qty, 0)})
							</span>
							<FontAwesomeIcon
								icon={faChevronUp}
								className="default-text clipboard-toggle-icon ms-2"
							/>
						</div>
					</div>
				</Container>
				<div className="clipboard-body">
					<Container>
						<Row>
							<Col className="col-md-3 col-12">
								<div className="clipboard-section rounded">
									<div className="clipboard-section-header flex-row-left background-default">
										<h3 className="title-sm white-text bold-text m-0">
											Pokémon
										</h3>
									</div>
									<div className="clipboard-section-body background-light">
										{clipboard
											.filter((clipItem) => clipItem.supertype == "Pokémon")
											.map((card) => (
												<div
													key={card.id}
													className="clipboard-item background-gray rounded flex-row-left"
													onClick={() => showPreview(card.img)}
												>
													<div className="clipboard-qty-picker flex-row-left">
														<Button
															className="card-qty-btn btn-primary flex-column"
															onClick={() => uncopyCard(card)}
														>
															<FontAwesomeIcon
																icon={faMinus}
																className="card-qty-icon"
															/>
														</Button>
														<span className="clipboard-qty default-text medium-text">
															{card.qty}
														</span>
														<Button
															className="card-qty-btn btn-primary flex-column"
															onClick={() => copyCard(card)}
														>
															<FontAwesomeIcon
																icon={faPlus}
																className="card-qty-icon"
															/>
														</Button>
													</div>
													<div className="clipboard-name-container">
														<span
															className="clipboard-name default-text"
															onClick={() => showPreview(card.img)}
														>
															{card.name} {card.ptcgo_code} {card.number}
														</span>
													</div>
													{/* <div className="clipboard-item-delete flex-column">
														<FontAwesomeIcon
															icon={faCircleXmark}
															className="clipboard-item-delete-btn text-lg default-text"
														/>
													</div> */}
												</div>
											))}
									</div>
								</div>
							</Col>
							<Col className="col-md-3 col-12">
								<div className="clipboard-section rounded">
									<div className="clipboard-section-header flex-row-left background-default">
										<h3 className="title-sm white-text bold-text m-0">
											Energy
										</h3>
									</div>
									<div className="clipboard-section-body background-light">
										{clipboard
											.filter((clipItem) => clipItem.supertype == "Energy")
											.map((card) => (
												<div
													key={card.id}
													className="clipboard-item background-gray rounded flex-row-left"
													onClick={() => showPreview(card.img)}
												>
													<div className="clipboard-qty-picker flex-row-left">
														<Button
															className="card-qty-btn btn-primary flex-column"
															onClick={() => uncopyCard(card)}
														>
															<FontAwesomeIcon
																icon={faMinus}
																className="card-qty-icon"
															/>
														</Button>
														<span className="clipboard-qty default-text medium-text">
															{card.qty}
														</span>
														<Button
															className="card-qty-btn btn-primary flex-column"
															onClick={() => copyCard(card)}
														>
															<FontAwesomeIcon
																icon={faPlus}
																className="card-qty-icon"
															/>
														</Button>
													</div>
													<div className="clipboard-name-container">
														<span
															className="clipboard-name default-text"
															onClick={() => showPreview(card.img)}
														>
															{card.name} {card.ptcgo_code} {card.number}
														</span>
													</div>
													{/* <div className="clipboard-item-delete flex-column">
														<FontAwesomeIcon
															icon={faCircleXmark}
															className="clipboard-item-delete-btn text-lg default-text"
														/>
													</div> */}
												</div>
											))}
									</div>
								</div>
							</Col>
							<Col className="col-md-3 col-12">
								<div className="clipboard-section rounded">
									<div className="clipboard-section-header flex-row-left background-default">
										<h3 className="title-sm white-text bold-text m-0">
											Trainer
										</h3>
									</div>
									<div className="clipboard-section-body background-light">
										{clipboard
											.filter((clipItem) => clipItem.supertype == "Trainer")
											.map((card) => (
												<div
													key={card.id}
													className="clipboard-item background-gray rounded flex-row-left"
													onClick={() => showPreview(card.img)}
												>
													<div className="clipboard-qty-picker flex-row-left">
														<Button
															className="card-qty-btn btn-primary flex-column"
															onClick={() => uncopyCard(card)}
														>
															<FontAwesomeIcon
																icon={faMinus}
																className="card-qty-icon"
															/>
														</Button>
														<span className="clipboard-qty default-text medium-text">
															{card.qty}
														</span>
														<Button
															className="card-qty-btn btn-primary flex-column"
															onClick={() => copyCard(card)}
														>
															<FontAwesomeIcon
																icon={faPlus}
																className="card-qty-icon"
															/>
														</Button>
													</div>
													<div className="clipboard-name-container">
														<span className="clipboard-name default-text">
															{card.name} {card.ptcgo_code} {card.number}
														</span>
													</div>
													{/* <div className="clipboard-item-delete flex-column">
														<FontAwesomeIcon
															icon={faCircleXmark}
															className="clipboard-item-delete-btn text-lg default-text"
														/>
													</div> */}
												</div>
											))}
									</div>
								</div>
							</Col>
							<Col className="col-md-3 col-12">
								<div className="clipboard-preview background-light rounded">
									{preview ? (
										<img
											src={preview}
											alt="Card preview"
											className="clipboard-img full"
										/>
									) : (
										""
									)}
								</div>
							</Col>
						</Row>
					</Container>
					<div className="clipboard-footer background-default flex-row-right full">
						<Container className="flex-row-right">
							<Button
								className="btn-purple clipboard-btn upper medium-text"
								onClick={clearClipboard}
							>
								Clear
							</Button>
							<Button
								className="btn-yellow clipboard-btn upper medium-text"
								onClick={copyToClipboard}
							>
								Copy
							</Button>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
};

export default Clipboard;
