const Button = ({
	className,
	variant,
	children,
	type,
	onClick,
	toggle,
	target,
	disabled,
}) => {
	return (
		<button
			type={type || "button"}
			className={`btn medium-text ${
				variant === "outlined" ? "outlined" : "btn-secondary"
			} ${className || ""}`}
			onClick={onClick}
			data-bs-toggle={toggle || ""}
			data-bs-target={target || ""}
			disabled={disabled}
		>
			{children}
		</button>
	);
};

export default Button;
