const ReplyArrow = ({ className }) => {
	return (
		<svg
			viewBox="0 0 24 24"
			width="18"
			height="18"
			className={`reply-arrow ${className}`}
		>
			<path d="M5.00014 13.9999L5 5.00003L7 5L7.00011 11.9999L17.1719 12L13.2222 8.05027L14.6364 6.63606L21.0004 13L14.6364 19.364L13.2222 17.9498L17.172 14L5.00014 13.9999Z"></path>
		</svg>
	);
};

export default ReplyArrow;
