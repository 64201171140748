import React, { createContext, useEffect, useState } from "react";

export const ViewContext = createContext();

export const ViewProvider = (props) => {
	const [view, setView] = useState(
		JSON.parse(localStorage.getItem("pokebuilderView")) || "list"
	);

	useEffect(() => {
		const fetchView = async () => {
			localStorage.setItem("pokebuilderView", JSON.stringify(view));
		};
		fetchView();
	}, [view]);

	return (
		<ViewContext.Provider value={[view, setView]}>
			{props.children}
		</ViewContext.Provider>
	);
};
