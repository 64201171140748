import { useEffect, useState, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { AccessContext } from "../context/AccessContext";
import { useSnackbar } from "react-simple-snackbar";
import { Helmet } from "react-helmet";

import BodyText from "../components/BodyText";
import Section from "../components/Section";
import Column from "../components/Column";
import Loader from "../components/Loader";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import MarkdownIt from "markdown-it";
import PageHeaderBg from "../components/PageHeaderBg";
import Deck from "../components/Deck";
import Info from "../components/Info";

const DeckBuilder = () => {
	const navigate = useNavigate();
	const [user] = useContext(UserContext);
	const [accessKey, setAccessKey] = useContext(AccessContext);
	const [createDeckModal, setCreateDeckModal] = useState(false);
	const [pasteDeckModal, setPasteDeckModal] = useState(false);
	const [loginModal, setLoginModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [creating, setCreating] = useState(false);
	const [searching, setSearching] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [decks, fetchDecks] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [deckName, setDeckName] = useState("");
	const [desc, setDesc] = useState("");
	const [cards, setCards] = useState("");
	const [invalidCardsModal, setInvalidCardsModal] = useState(false);
	const [invalidCards, setInvalidCards] = useState([]);

	const mdParser = new MarkdownIt();

	const handleEditorChange = ({ html, text }) => {
		setDesc(text);
	};

	try {
		var user_obj = JSON.parse(user);
		var auth_token = user_obj.auth_token;
	} catch {
		var user_obj = null;
		var auth_token = null;
	}

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = () => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
				"X-Access-Key": accessKey,
			},
		};

		fetch(
			`${process.env.REACT_APP_API_URI}/decks/my_decks${window.location.search}`,
			requestOptions
		)
			.then((res) => res.json())
			.then((res) => {
				fetchDecks(res.data);
				fetchMeta(res.meta);
				setLoading(false);
				setSearching(false);
				if (res.access_key && res.access_key != "") {
					setAccessKey(res.access_key);
				}
			});
	};

	const createBlankDeck = async (e) => {
		setCreating(true);
		e.preventDefault();
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				access_key: accessKey,
				name: deckName,
				desc: desc,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/create`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.message);
		} else {
			if (data.access_key) {
				setAccessKey(data.access_key);
			}
			setCreateDeckModal(false);
			successSnackbar("Deck created.");
			navigate("/decks/" + data.deck_id);
		}
		setCreating(false);
	};

	const pasteDeck = async (e, force = false) => {
		setCreating(true);
		e.preventDefault();
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				access_key: accessKey,
				name: deckName,
				cards: cards,
				force: force,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/paste`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.message);
		} else {
			if (data.invalid_cards) {
				setInvalidCards(data.invalid_cards);
				setInvalidCardsModal(true);
			} else {
				if (data.access_key) {
					setAccessKey(data.access_key);
				}
				setCreateDeckModal(false);
				successSnackbar("Deck created.");
				navigate("/decks/" + data.deck_id);
			}
		}
		setCreating(false);
	};

	const handleCreateDeck = (func) => {
		if (!user_obj && decks.length > 0) {
			setLoginModal(true);
		} else {
			func(true);
		}
	};

	useEffect(() => {
		getData();
	}, [searchParams, user]);

	return (
		<>
			<Helmet>
				<title>Deck Builder | Pokebuilder</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : (
				<>
					<Section className="full-page pb-5">
						<div className="page-header builder flex-row-left">
							<Container>
								<h2 className="page-header-title text-xl default-text bold-text upper">
									My Decks ({decks.length})
								</h2>
							</Container>
							<PageHeaderBg className="builder" />
						</div>
						<Container>
							<div className="page-content-header flex-row-left background-default rounded">
								<div className="deck-filters-row col-lg-8 col-12 flex-row-left">
									<Button
										className="builder-header-btn rounded flex-row-center btn-yellow upper default-text bold-text"
										onClick={() => handleCreateDeck(setCreateDeckModal)}
									>
										Create Deck
									</Button>
									<Button
										className="builder-header-btn rounded flex-row-center btn-purple upper default-text bold-text"
										onClick={() => handleCreateDeck(setPasteDeckModal)}
									>
										<Info className="btn-info-icon" />
										Paste Deck
									</Button>
								</div>
								<div className="col-lg-4 col-12 flex-row-right">
									<Dropdown>
										<Dropdown.Toggle className="filter-btn flex-row-center">
											Sort
										</Dropdown.Toggle>

										<Dropdown.Menu>
											<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
											<Dropdown.Item href="#/action-2">
												Another action
											</Dropdown.Item>
											<Dropdown.Item href="#/action-3">
												Something else
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
							<Row>
								{decks.map((deck) => (
									<Col
										className="col-lg-3 col-md-4 col-sm-6 col-12"
										key={deck.id}
									>
										<Deck
											deck={deck}
											user_id={user_obj ? user_obj.user_id : null}
										/>
									</Col>
								))}
							</Row>
						</Container>
					</Section>
					<Modal show={createDeckModal} centered>
						<Modal.Header className="background-default">
							<Modal.Title className="white-text medium-text title-sm">
								Create Deck
							</Modal.Title>
						</Modal.Header>
						<Form onSubmit={createBlankDeck}>
							<Modal.Body className="pt-4 pb-4">
								<Row className="mb-4">
									<Column>
										<label className="default-text bold-text">Deck Name</label>
										<Form.Control
											className="modal-input"
											placeholder="Enter deck name"
											value={deckName}
											onChange={(e) => setDeckName(e.target.value)}
											required
										/>
									</Column>
								</Row>
								<Row>
									<Column>
										<label className="default-text bold-text">
											Description (Optional)
										</label>
										<Form.Control
											as="textarea"
											className="modal-input"
											value={desc}
											onChange={(e) => setDesc(e.target.value)}
										/>
									</Column>
								</Row>
							</Modal.Body>
							<Modal.Footer className="ps-0 pe-0">
								<Row className="full">
									<Col>
										<Button
											className="btn btn-purple modal-btn full upper medium-text"
											onClick={() => setCreateDeckModal(false)}
										>
											Close
										</Button>
									</Col>
									<Col>
										<Button
											type="submit"
											className="btn btn-yellow modal-btn full upper medium-text"
											disabled={creating}
										>
											{creating ? "Creating.." : "Create Deck"}
										</Button>
									</Col>
								</Row>
							</Modal.Footer>
						</Form>
					</Modal>
					<Modal show={pasteDeckModal} centered>
						<Modal.Header className="background-default">
							<Modal.Title className="white-text medium-text title-sm">
								Paste Deck
							</Modal.Title>
						</Modal.Header>
						<Form onSubmit={(e) => pasteDeck(e, false)}>
							<Modal.Body className="pt-4 pb-4">
								<Row>
									<Column>
										<label className="default-text bold-text">Deck Name</label>
										<Form.Control
											className="modal-input"
											placeholder="Enter deck name"
											value={deckName}
											onChange={(e) => setDeckName(e.target.value)}
											required
										/>
									</Column>
								</Row>
								<br />
								<Row>
									<Column>
										<label className="default-text bold-text">Cards</label>
										<p className="default-text p-label">
											Copy and paste cards from your deck clipboard.
										</p>
										<Form.Control
											as="textarea"
											className="modal-input"
											value={cards}
											onChange={(e) => setCards(e.target.value)}
											required
										/>
									</Column>
								</Row>
							</Modal.Body>
							<Modal.Footer className="ps-0 pe-0">
								<Row className="full">
									<Col>
										<Button
											className="btn btn-purple modal-btn full upper medium-text"
											onClick={() => setPasteDeckModal(false)}
										>
											Close
										</Button>
									</Col>
									<Col>
										<Button
											type="submit"
											className="btn btn-yellow modal-btn full upper medium-text"
											disabled={creating}
										>
											{creating ? "Creating.." : "Create Deck"}
										</Button>
									</Col>
								</Row>
							</Modal.Footer>
						</Form>
					</Modal>
					<Modal
						show={invalidCardsModal}
						onHide={() => setInvalidCardsModal(false)}
						className="invalid-cards-modal"
						centered
					>
						<Modal.Header>
							<Modal.Title className="default-text bold-text">
								Invalid Cards
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className="pt-4 pb-4">
							<BodyText>
								We couldn't find some of the cards in your list. This deck will
								be created without the following:
							</BodyText>
							<BodyText className="invalid-cards default-text">
								{invalidCards.map((invalidCard) => invalidCard).join(", ")}
							</BodyText>
						</Modal.Body>
						<Modal.Footer>
							<button
								type="button"
								className="btn btn-default modal-btn"
								onClick={() => setInvalidCardsModal(false)}
							>
								Cancel
							</button>
							<button
								type="button"
								className="btn btn-primary modal-btn"
								onClick={(e) => pasteDeck(e, true)}
							>
								Proceed
							</button>
						</Modal.Footer>
					</Modal>
					<Modal
						show={loginModal}
						onHide={() => setLoginModal(false)}
						className="invalid-cards-modal"
						centered
					>
						<Modal.Header>
							<Modal.Title className="default-text bold-text">
								Log in required
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className="pt-4 pb-4">
							<BodyText>
								You need to be logged in to create more decks.
							</BodyText>
						</Modal.Body>
						<Modal.Footer className="ps-0 pe-0">
							<Row className="full">
								<Col>
									<Button
										className="btn btn-purple modal-btn full upper medium-text"
										onClick={() => setLoginModal(false)}
									>
										Close
									</Button>
								</Col>
								<Col>
									<Button
										type="button"
										className="btn btn-yellow modal-btn full upper medium-text"
										onClick={() => navigate("/login")}
									>
										Log In
									</Button>
								</Col>
							</Row>
						</Modal.Footer>
					</Modal>
				</>
			)}
		</>
	);
};

export default DeckBuilder;
