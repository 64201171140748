const PokeSilhouette = ({ className }) => {
	return (
		<svg
			className={`pokeball ${className}`}
			width="129"
			height="101"
			viewBox="0 0 129 101"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M64.2997 -14.9253C33.244 -14.9253 7.08912 6.91968 0.488088 36.0192C-0.434357 40.0819 2.76067 43.9541 6.92802 43.9541H41.7599C44.0889 43.9541 46.211 42.698 47.3821 40.683C50.7905 34.8343 57.0706 30.8698 64.2997 30.8698C71.5287 30.8698 77.8088 34.8343 81.2173 40.683C82.3883 42.698 84.5104 43.9541 86.8394 43.9541H121.671C125.845 43.9541 129.034 40.0819 128.111 36.0192C121.51 6.91968 95.3553 -14.9253 64.2997 -14.9253ZM64.2997 43.9541C62.5646 43.9541 60.9006 44.6434 59.6737 45.8703C58.4468 47.0971 57.7575 48.7612 57.7575 50.4963C57.7575 52.2313 58.4468 53.8954 59.6737 55.1223C60.9006 56.3492 62.5646 57.0384 64.2997 57.0384C66.0347 57.0384 67.6988 56.3492 68.9257 55.1223C70.1525 53.8954 70.8418 52.2313 70.8418 50.4963C70.8418 48.7612 70.1525 47.0971 68.9257 45.8703C67.6988 44.6434 66.0347 43.9541 64.2997 43.9541ZM6.92802 57.0384C2.75413 57.0384 -0.434357 60.9107 0.488088 64.9733C7.08912 94.0728 33.244 115.918 64.2997 115.918C95.3553 115.918 121.504 94.0728 128.111 64.9733C129.034 60.9107 125.839 57.0384 121.671 57.0384H86.8394C84.5104 57.0384 82.3883 58.2945 81.2173 60.3095C77.8088 66.1582 71.5287 70.1227 64.2997 70.1227C57.0706 70.1227 50.7905 66.1582 47.3821 60.3095C46.211 58.2945 44.0889 57.0384 41.7599 57.0384H6.92802Z"
				fill="#E28B08"
			/>
		</svg>
	);
};

export default PokeSilhouette;
