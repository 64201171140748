import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { RequestForToken, onMessageListener } from "./firebase";

const Notification = () => {
	const [notification, setNotification] = useState({
		title: "",
		body: "",
		url: "",
	});
	const notify = () => toast(<ToastDisplay />);
	function ToastDisplay() {
		return (
			<div className="notification">
				<div className="flex-row-left mb-2">
					{/* <i className="ri-chat-4-fill text-blue text-lg me-2" /> */}
					<span className="medium-text text-sm text-white">
						{notification?.title}
					</span>
				</div>
				<p className="text-sm mb-0 ellipsis text-white">{notification?.body}</p>
			</div>
		);
	}

	useEffect(() => {
		if (notification?.title) {
			notify();
		}
	}, [notification]);

	RequestForToken();

	onMessageListener()
		.then((payload) => {
			setNotification({
				title: payload?.notification?.title,
				body: payload?.notification?.body,
				url: payload?.data?.url,
			});
		})
		.catch((err) => console.log("failed: ", err));

	return (
		<a href={notification?.url ? notification?.url : "#"}>
			<Toaster
				position="bottom-right"
				toastOptions={{ className: "notification-container" }}
			/>
		</a>
	);
};

export default Notification;
