import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { AccessContext } from "../context/AccessContext";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { Helmet } from "react-helmet";
import Section from "../components/Section";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const Login = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [, setUser] = useContext(UserContext);
	const [accessKey, setAccessKey] = useContext(AccessContext);

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const submitLogin = async (e) => {
		e.preventDefault();
		setLoading(true);
		setEmailError(false);
		setPasswordError(false);
		setErrorMessage("");
		if (validate() === true) {
			const requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					email: email,
					password: password,
					access_key: accessKey,
				}),
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URI}/users/login`,
				requestOptions
			);
			const data = await response.json();

			if (!response.ok) {
				setErrorMessage(data.msg);
				if (data.type === "email") {
					setEmailError(true);
				} else {
					setPasswordError(true);
				}
				setPassword("");
			} else {
				setUser(JSON.stringify(data.user));
				setAccessKey("");
				navigate("/");
			}
		}
		setLoading(false);
	};

	const validate = () => {
		if (!email || !password) {
			setErrorMessage("Please fill out all required fields.");

			if (!email) {
				setEmailError(true);
			}
			if (!password) {
				setPasswordError(true);
			}

			return false;
		} else {
			return true;
		}
	};

	return (
		<>
			<Helmet>
				<title>Log In | Pokebuilder</title>
			</Helmet>
			<Section className="full-page flex-column">
				<Form
					className="form-card rounded background-light col-lg-4 col-md-6 col-11"
					onSubmit={submitLogin}
				>
					<h1 className="login-title default-text bold-text title-lg align-center upper">
						Log in
					</h1>
					<label className="default-text bold-text">Username/Email</label>
					<Form.Control
						className={`login-input login-block default-text ${
							emailError && "error"
						}`}
						placeholder="Enter username/email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<label className="default-text bold-text">Password</label>
					<Form.Control
						type="password"
						className={`login-input login-block default-text ${
							passwordError && "error"
						}`}
						placeholder="Enter password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					{errorMessage && (
						<span className="form-error-msg text-danger">{errorMessage}</span>
					)}
					<Button
						type="submit"
						className="login-block btn-yellow login-btn upper medium-text full"
						disabled={loading}
					>
						Log In
					</Button>
					<div className="login-block align-center">
						<span className="default-text m-0">
							Don't have an account?{" "}
							<Link className="bold-text" to="/signup">
								Sign Up
							</Link>
						</span>
					</div>
					<div className="align-center">
						<a href="/password/forgot" className="default-text bold-text">
							Forgot Password
						</a>
					</div>
				</Form>
			</Section>
		</>
	);
};

export default Login;
