import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
	const [user, setUser] = useState(localStorage.getItem("pokebuilderUser"));

	useEffect(() => {
		const fetchUser = async () => {
			try {
				var token = JSON.parse(user).auth_token;
			} catch {
				var token = null;
			}
			const requestOptions = {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-Auth-Token": token,
				},
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URI}/users/me`,
				requestOptions
			);

			if (!response.ok) {
				setUser(null);
			}
			localStorage.setItem("pokebuilderUser", user);
		};
		fetchUser();
	}, [user]);

	return (
		<UserContext.Provider value={[user, setUser]}>
			{props.children}
		</UserContext.Provider>
	);
};
