import placeholder from "../assets/card_placeholder.jpg";

const CardPlaceholder = ({ className }) => {
	return (
		<img
			src={placeholder}
			alt="Card photo placeholder"
			className={`card-placeholder ${className || ""}`}
		/>
	);
};

export default CardPlaceholder;
