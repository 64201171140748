import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Pagination = ({ meta, nextPage, previousPage, className }) => {
	return (
		<div className={`pagination-container ${className}`}>
			<span className="pagination-label bold-text">
				{meta.end > 0 ? meta.start + 1 : 0} - {meta.end} &nbsp; of &nbsp;{" "}
				{meta.total}
			</span>
			{meta.start == 0 ? (
				<button className="pagination-btn previous btn flex-column" disabled>
					<FontAwesomeIcon icon={faChevronLeft} />
				</button>
			) : (
				<button
					className="pagination-btn previous btn flex-column"
					onClick={previousPage}
				>
					<FontAwesomeIcon icon={faChevronLeft} />
				</button>
			)}
			{!meta.next ? (
				<button className="pagination-btn next btn flex-column" disabled>
					<FontAwesomeIcon icon={faChevronRight} />
				</button>
			) : (
				<button
					className="pagination-btn next btn flex-column"
					onClick={nextPage}
				>
					<FontAwesomeIcon icon={faChevronRight} />
				</button>
			)}
		</div>
	);
};

export default Pagination;
