import { useState, useEffect } from "react";
import banner1 from "../assets/banner1.png";
import banner2 from "../assets/banner2.png";

const Hero = () => {
	const banners = [
		{ url: "https://twitter.com", img: banner1 },
		{ url: "https://twitter.com", img: banner2 },
	];
	const [activeBanner, setActiveBanner] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			if (activeBanner === banners.length - 1) {
				setActiveBanner(0);
			} else {
				setActiveBanner(activeBanner + 1);
			}
		}, 5000);

		return () => {
			clearInterval(interval);
		};
	}, [activeBanner]);

	return (
		<div className="home-hero rounded full">
			<a
				href={banners[activeBanner].url}
				target="_blank"
				rel="noopener noreferrer"
			>
				<img
					src={banners[activeBanner].img}
					alt="Banner photo"
					className="full"
				/>
			</a>
			<div className="banner-counter full flex-row-left">
				{banners.map((banner, index) => (
					<a
						key={index}
						href="#"
						onClick={() => setActiveBanner(index)}
						className={`banner-counter-item ${
							index === activeBanner && "active"
						}`}
					/>
				))}
			</div>
		</div>
	);
};

export default Hero;
