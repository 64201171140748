import { useState, useContext } from "react";
import { ClipboardContext } from "../context/ClipboardContext";
import { Link } from "react-router-dom";
import BodyText from "../components/BodyText";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSnackbar } from "react-simple-snackbar";
import CardPlaceholder from "./CardPlaceholder";
import Loader from "../components/Loader";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import Spinner from "react-bootstrap/Spinner";

const DeckCard = ({
	id,
	createViewColumn,
	view,
	card,
	deckID,
	setCards,
	className,
	qty,
	ownedByUser,
	authToken,
	fetchDeck,
	setDeckView,
	setPokemonCards,
	setEnergyCards,
	setTrainerCards,
	setPreview,
	lockQty,
	centerQty,
}) => {
	const [loading, setLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [clipboard, setClipboard] = useContext(ClipboardContext);
	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [defaultSnackbar] = useSnackbar();

	const copyCard = (card) => {
		if (clipboard.filter((existing) => existing.id == card.id).length != 0) {
			const newClipboard = [...clipboard];
			newClipboard.filter((existing) => existing.id == card.id)[0].qty += 1;
			setClipboard(newClipboard);
		} else {
			setClipboard((clipboard) => [
				...clipboard,
				{
					id: card.id,
					tcg_id: card.tcg_id,
					number: card.number,
					ptcgo_code: (card.card_set && card.card_set.ptcgo_code) || "",
					supertype: card.supertype,
					img: card.lg_img,
					name: card.name,
					qty: 1,
				},
			]);
		}
		defaultSnackbar("Added to clipboard.");
	};

	const topCard = () => {
		setCards((cardsToCopy) => [
			...cardsToCopy,
			{
				index: `${cardsToCopy.length}`,
				id: `${card.id}`,
				tcg_id: `${card.tcg_id}`,
				img: `${card.lg_img}`,
				name: `${card.name}`,
			},
		]);
	};

	const editCards = async (url) => {
		setLoading(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": authToken,
			},
			body: JSON.stringify({
				card_id: `${card.id}`,
				deck_id: `${deckID}`,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/${url}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log(data.message);
		} else {
			fetchDeck(data.deck);
			setPokemonCards(
				data.deck.deck_cards
					.filter((deck_card) => deck_card.card.supertype == "Pokémon")
					.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
			);
			setEnergyCards(
				data.deck.deck_cards
					.filter((deck_card) => deck_card.card.supertype == "Energy")
					.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
			);
			setTrainerCards(
				data.deck.deck_cards
					.filter((deck_card) => deck_card.card.supertype == "Trainer")
					.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
			);
		}
		setLoading(false);
	};

	return (
		<>
			<ReactTooltip />
			{view === "grid" ? (
				<div
					className={`deck-grid-item flex-column ${className || ""} ${
						loading && "active"
					}`}
				>
					{!isLoaded ? (
						<CardPlaceholder className="deck-card-placeholder" />
					) : (
						""
					)}
					<span
						className={`card-grid-qty background-default bold-text rounded white-text flex-column ${
							centerQty && "centered"
						}`}
					>
						{qty}
					</span>
					<Link
						to={`/cards/${card.id}`}
						data-html={true}
						data-effect="solid"
						data-place="right"
						data-class="card-tooltip"
						data-type="light"
						data-delay-show="300"
						data-tip={`
				<img src=${card.lg_img} alt="Card photo" className="tooltip-img"><br /><br />
				<span><strong>Format: </strong>${card.card_format}</span><br />
				<span><strong>Set: </strong>${card.card_set && card.card_set.name} <img src=${
							card.card_set && card.card_set.symbol
						} alt="Set logo" /></span><br />
				<span><strong>Illustrator: </strong>${card.artist && card.artist}</span><br />
			`}
					>
						<img
							src={card.lg_img}
							alt="Card photo"
							className={`deck-card-img ${!isLoaded ? "hidden" : ""}`}
							onLoad={() => setIsLoaded(true)}
						/>
					</Link>
					{ownedByUser && !lockQty && (
						<div className="qty-picker flex-column">
							<div className="flex-row-center">
								{loading ? (
									<Loader className="deck-card-loader" />
								) : (
									<>
										<Button
											className="qty-btn flex-column"
											onClick={() => editCards("delete_card")}
										>
											-
										</Button>
										<h5 className="white-text bold-text qty-label">{qty}</h5>
										<Button
											className="qty-btn flex-column"
											onClick={() => editCards("add_card")}
										>
											+
										</Button>
									</>
								)}
							</div>
							<Link to={`/cards/${card.id}`}>
								<BodyText className="white-text bold-text m-0">
									CARD INFO
								</BodyText>
							</Link>
						</div>
					)}
				</div>
			) : (
				<div
					key={card.id}
					className={`clipboard-item deck-clipboard-item background-gray rounded flex-row-left ${
						ownedByUser && "owned"
					}`}
					onMouseOver={() => setPreview(card)}
				>
					<div className="clipboard-qty-picker flex-row-left">
						{ownedByUser && (
							<Button
								className="card-qty-btn btn-primary flex-column"
								onClick={() => editCards("delete_card")}
							>
								<FontAwesomeIcon icon={faMinus} className="card-qty-icon" />
							</Button>
						)}
						{loading ? (
							<div className="deck-card-qty flex-column">
								<Spinner
									as="span"
									animation="border"
									size="sm"
									className="default-text"
								/>
							</div>
						) : (
							<span className="clipboard-qty default-text bold-text">
								{qty}
							</span>
						)}
						{ownedByUser && (
							<Button
								className="card-qty-btn btn-primary flex-column"
								onClick={() => editCards("add_card")}
							>
								<FontAwesomeIcon icon={faPlus} className="card-qty-icon" />
							</Button>
						)}
					</div>
					<div className="clipboard-name-container">
						<Link
							to={`/cards/${card.id}`}
							className="clipboard-name deck-clipboard-name default-text"
						>
							{card.name} {card.ptcgo_code} {card.number}
						</Link>
					</div>
				</div>
			)}
		</>
	);
};
export default DeckCard;
