import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { Helmet } from "react-helmet";
import Section from "../components/Section";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Loader from "../components/Loader";

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [sent, setSent] = useState(false);
	const [loading, setLoading] = useState(false);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [defaultSnackbar] = useSnackbar();

	const submitRequest = async (e) => {
		e.preventDefault();
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				email: email,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/password/forgot`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data[0].msg);
		} else {
			successSnackbar(data.msg);
			setSent(true);
		}
		setLoading(false);
	};

	return (
		<>
			<Helmet>
				<title>Forgot Password | Pokebuilder</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : (
				<Section className="full-page flex-column">
					{sent ? (
						<div className="p-5 flex-column background-light rounded">
							<h1 className="text-xl default-text bold-text">
								Check your email.
							</h1>
							<p className="default-text m-0">
								We've sent you the link to reset your password.
							</p>
						</div>
					) : (
						<>
							<Form
								className="form-card background-light rounded col-md-4"
								onSubmit={submitRequest}
							>
								<h1 className="login-title default-text bold-text title-lg align-center upper">
									Forgot Password
								</h1>
								<div className="p-label align-center">
									<p>We'll send you a link to reset your password.</p>
								</div>
								<label className="default-text bold-text">Email</label>
								<Form.Control
									type="email"
									className="login-input login-block default-text"
									placeholder="Enter Email"
									onChange={(e) => setEmail(e.target.value)}
									required
								/>
								<Button
									type="submit"
									className="login-block btn-yellow login-btn upper medium-text full"
								>
									Send Reset Link
								</Button>
								<div className="login-block align-center">
									<span className="default-text m-0">
										Don't have an account?{" "}
										<Link className="bold-text" to="/signup">
											Sign Up
										</Link>
									</span>
								</div>
							</Form>
						</>
					)}
				</Section>
			)}
		</>
	);
};

export default ForgotPassword;
