const Copy = ({ className, fill }) => {
	return (
		<svg
			className={className}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.6698 4.37825L15.5473 3.43315C15.0177 2.57673 14.0802 1.99902 13.0008 1.99902H4.99915C3.34712 1.99902 1.99915 3.347 1.99915 4.99902V16.999C1.99915 18.6536 3.34712 19.999 4.99915 19.999H7.3657L15.6486 21.9247C15.8741 21.9754 16.0971 22.0007 16.3176 22.0007C17.6858 22.0007 18.9248 21.0581 19.2441 19.6696L21.9248 7.97115C22.2922 6.35967 21.2812 4.74818 19.6698 4.37825ZM4.00084 16.999V4.99902C4.00084 4.44919 4.44678 4.00071 4.99915 4.00071H13.0008C13.5532 4.00071 13.9991 4.44919 13.9991 4.99902V16.999C13.9991 17.5514 13.5532 17.9999 13.0008 17.9999H4.99915C4.44678 17.9999 4.00084 17.5514 4.00084 16.999ZM19.9738 7.52521L17.2931 19.2212C17.1689 19.7583 16.6267 20.0953 16.0997 19.9737L14.473 19.5962C15.3801 19.0818 16.0008 18.1164 16.0008 16.999V5.5894L19.2238 6.32673C19.761 6.45088 20.098 6.98804 19.9738 7.52521Z"
				fill={fill || "white"}
			/>
		</svg>
	);
};

export default Copy;
