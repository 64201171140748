import { Link } from "react-router-dom";
import SectionHeader from "./SectionHeader";
import Column from "./Column";

const Set = ({ set, className }) => {
	return (
		<Column className={`${className || ""} `}>
			<Link to={`/sets/${set.id}`}>
				<div className="set background-light rounded flex-column">
					<div className="set-logo-container flex-column">
						<img src={set.logo} alt="Set logo" className="set-logo" />
					</div>
					<span className="text-lg default-text bold-text align-center">
						{set.name}
					</span>
					<div className="set-date-container full background-gray flex-column">
						<span className="label-md default-text">
							{new Date(set.release_date).toLocaleDateString()}
						</span>
					</div>
				</div>
			</Link>
		</Column>
	);
};

export default Set;
