import { Link } from "react-router-dom";
import Section from "../components/Section";
import soon from "../assets/soon.png";
import Button from "../components/Button";

const ComingSoon = () => {
	return (
		<Section className="half-page flex-column pt-5 pb-5">
			<img src={soon} alt="Coming soon page graphics" className="soon-img" />
			<h1 className="default-text bold-text mt-3">Coming soon!</h1>
			<Link to="/" className="mt-3">
				<Button className="btn-primary">Back to home</Button>
			</Link>
		</Section>
	);
};

export default ComingSoon;
