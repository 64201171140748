import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebookF,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import Section from "./Section";
import Container from "./Container";
import Row from "./Row";
import Column from "./Column";
import BodyText from "./BodyText";

const Footer = () => {
	return (
		<Section className="footer background-default">
			<Container>
				<Row className="footer-nav pb-3">
					<Column className="col-md-6 col-12">
						<Link to="/about" className="footer-nav-link me-4 white-text">
							About
						</Link>
						<Link to="/contact" className="footer-nav-link me-4 white-text">
							Contact
						</Link>
						<Link to="/privacy" className="footer-nav-link me-4 white-text">
							Privacy
						</Link>
						<a
							href="https://buy.stripe.com/4gwbKUcT519z0Vy5kk"
							className="footer-nav-link me-4 white-text"
						>
							Donate
						</a>
					</Column>
					<Column className="footer-socials align-right col-md-6 col-12">
						<a href="https://facebook.com" className="footer-social ms-5">
							<FontAwesomeIcon icon={faFacebookF} className="white-text" />
						</a>
						<a
							href="https://twitter.com/pokebuilder_net"
							className="footer-social ms-5"
						>
							<FontAwesomeIcon icon={faTwitter} className="white-text" />
						</a>
						<a href="https://instagram.com" className="footer-social ms-5">
							<FontAwesomeIcon icon={faInstagram} className="white-text" />
						</a>
					</Column>
				</Row>
				<BodyText className="footer-text white-text align-center">
					Pokemon and its trademarks are 1995-2020 Nintendo, Creatures, and
					GAMEFREAK. English card images appearing on this website are property
					of The Pokemon Company International, Inc. This website is not in any
					way affiliated with or sponsored by the aforementions.
				</BodyText>
			</Container>
		</Section>
	);
};

export default Footer;
