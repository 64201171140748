import React, { createContext, useEffect, useState } from "react";

export const AccessContext = createContext();

export const AccessProvider = (props) => {
	const [accessKey, setAccessKey] = useState(
		localStorage.getItem("pokebuilderAccess") || ""
	);

	useEffect(() => {
		const fetchAccessKey = async () => {
			localStorage.setItem("pokebuilderAccess", accessKey);
		};
		fetchAccessKey();
	}, [accessKey]);

	return (
		<AccessContext.Provider value={[accessKey, setAccessKey]}>
			{props.children}
		</AccessContext.Provider>
	);
};
