import { useEffect, useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { Helmet } from "react-helmet";

import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Button from "react-bootstrap/Button";
import Loader from "../components/Loader";
import Set from "../components/Set";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import PageHeaderBg from "../components/PageHeaderBg";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "../components/Pagination";

const Sets = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [searching, setSearching] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [sets, fetchSets] = useState([]);
	const [meta, fetchMeta] = useState([]);

	const [name, setName] = useState(searchParams.get("q") || "");
	const [activeFormat, setActiveFormat] = useState("all");
	const [items, fetchItems] = useState([]);
	const [selectedItem, setSelectedItem] = useState(null);
	const [rawSelected, setRawSelected] = useState(true);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [loadingSuggestions, setLoadingSuggestions] = useState(false);
	const [fromSuggestions, setFromSuggestions] = useState(false);
	const [rawName, setRawName] = useState("");
	const [searchFocused, setSearchFocused] = useState(false);
	const [formatFilter, setFormatFilter] = useState(
		searchParams.get("format") ? searchParams.get("format") : ""
	);

	const handleSearchClear = () => {
		setName("");
		setSearchParams({
			page: 1,
			q: "",
		});
	};

	const formatResult = (item) => {
		return (
			<>
				<span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
			</>
		);
	};

	const searchStyling = {
		display: "block",
		fontFamily: "Rajdhani-Medium",
		width: "100%",
		padding: "0.375rem 0.75rem",
		fontSize: "1rem",
		lineHeight: "1.5",
		color: "#495057",
		backgroundColor: "#fff",
		backgroundClip: "padding-box",
		border: "1px solid #ced4da",
		borderRadius: "0.25rem",
		height: "36px",
		boxShadow: "none !important",
	};

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [defaultSnackbar] = useSnackbar();

	if (!searchParams.get("page")) {
		setSearchParams({ page: 1 });
	}

	const getData = () => {
		fetch(
			`${process.env.REACT_APP_API_URI}/cards/sets${window.location.search}`
		)
			.then((res) => res.json())
			.then((res) => {
				fetchSets(res.data);
				fetchMeta(res.meta);
				setLoading(false);
				setSearching(false);
			});
	};

	const nextPage = () => {
		setSearchParams({
			page: parseInt(searchParams.get("page")) + 1,
			q: name,
		});
	};

	const previousPage = () => {
		setSearchParams({
			page: parseInt(searchParams.get("page")) - 1,
			q: name,
		});
	};

	const handleFilter = (e) => {
		e.preventDefault();
		if (selectedItem !== null) {
			navigate(`/sets/${items[selectedItem].id}`);
		} else {
			setSearchParams({
				page: 1,
				q: name,
				format: formatFilter,
			});
		}
		setShowSuggestions(false);
	};

	const clearFilter = () => {
		setName("");
	};

	const highlightSuggestion = (e) => {
		if (e.keyCode === 40) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem < items.length - 1) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem + 1);
				} else {
					if (rawSelected) {
						setSelectedItem(0);
						setRawSelected(false);
					} else {
						setSelectedItem(null);
						setRawSelected(true);
					}
				}
			} else {
				setRawSelected(!rawSelected);
			}
		} else if (e.keyCode === 38) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem !== 0) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem - 1);
				} else {
					if (selectedItem === 0) {
						setSelectedItem(null);
						setRawSelected(true);
					} else {
						setSelectedItem(items.length - 1);
						setRawSelected(false);
					}
				}
			} else {
				setRawSelected(!rawSelected);
			}
		} else {
			setFromSuggestions(false);
		}
	};
	const handleName = (name) => {
		setName(name);
		setRawName(name);
	};

	const handleSuggestionHover = () => {
		setRawSelected(false);
		setFromSuggestions(true);
		setSelectedItem(null);
	};

	const handleSearchSelect = (e, item_name) => {
		e.preventDefault();
		setShowSuggestions(false);
		setRawSelected(true);
		if (item_name) {
			setName(item_name);
			setSearchParams({
				page: 1,
				q: item_name,
				format: formatFilter,
			});
		}
	};

	const getSuggestions = () => {
		setLoadingSuggestions(true);
		fetch(`${process.env.REACT_APP_API_URI}/cards/sets/suggestions?q=${name}`)
			.then((res) => res.json())
			.then((res) => {
				setSelectedItem(null);
				fetchItems(res.data);
				setLoadingSuggestions(false);
			});
	};

	useEffect(() => {
		setSearching(true);
		getData();
	}, [searchParams]);

	useEffect(() => {
		if (!fromSuggestions) {
			if (name !== "" && searchFocused) {
				setShowSuggestions(true);
				setLoadingSuggestions(true);
				const timer = setTimeout(() => {
					getSuggestions();
				}, 500);
				return () => clearTimeout(timer);
			} else {
				setShowSuggestions(false);
				setLoadingSuggestions(true);
			}
		}
	}, [name]);

	return (
		<>
			<Helmet>
				<title>Sets | Pokebuilder</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : (
				<Section className="full-page pb-5">
					<div className="page-header sets flex-row-left">
						<Container>
							<h1 className="page-header-title text-xl white-text bold-text upper mb-0">
								Sets
							</h1>
						</Container>
						<PageHeaderBg className="sets" />
					</div>
					<Container>
						<div className="deck-filters main flex-row-left">
							<div className="deck-filters-row col-lg-8 col-12 flex-row-left">
								<div className="search-container">
									<Form onSubmit={handleFilter}>
										<input
											value={name}
											className="form-control search-text rounded"
											placeholder="Search sets"
											type="text"
											onChange={(e) => handleName(e.target.value)}
											onKeyDown={highlightSuggestion}
											onFocus={() => setSearchFocused(true)}
										/>
										<Button
											type="submit"
											className="search-input-icon flex-row-right"
										>
											<FontAwesomeIcon icon={faSearch} />
										</Button>
									</Form>
									<div
										className={`suggestions-container background-white ${
											showSuggestions ? "" : "hidden"
										}`}
									>
										{name && (
											<a
												href="#"
												className={`raw-search-result search-suggestion default-text ellipsis ${
													rawSelected && "selected"
												}`}
												onMouseOver={handleSuggestionHover}
												onClick={(e) => handleSearchSelect(e, rawName)}
											>
												<FontAwesomeIcon icon={faSearch} className="me-2" />
												Search for "{rawName}"
											</a>
										)}
										{!loadingSuggestions ? (
											items.map((item, index) => (
												<Link
													to={`/sets/${item.id}`}
													key={index}
													onMouseOver={handleSuggestionHover}
													className={`search-suggestion deck-suggestion default-text ellipsis ${
														selectedItem === index ? "selected" : ""
													}`}
												>
													<div className="flex-row-left">
														<div className="suggestion-info">
															<span className="suggestion-name bold-text m-0">
																{item.name}
															</span>
														</div>
													</div>
												</Link>
											))
										) : (
											<div className="suggestions-loader flex-column">
												<Spinner
													as="span"
													animation="border"
													size="sm"
													className="default-text"
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						{searching ? (
							<Loader className="search-loader" />
						) : (
							<>
								<Row>
									{sets.length != 0
										? sets.map((set) => (
												<Set
													key={set.id}
													set={set}
													className="col-lg-3 col-md-6 col-12 mb-4"
												/>
										  ))
										: ""}
								</Row>
								<Pagination
									meta={meta}
									nextPage={nextPage}
									previousPage={previousPage}
									className="flex-row-center mt-5 default-text"
								/>
							</>
						)}
					</Container>
				</Section>
			)}
		</>
	);
};

export default Sets;
