import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { AccessContext } from "../context/AccessContext";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { Helmet } from "react-helmet";
import Section from "../components/Section";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SignUp = () => {
	const navigate = useNavigate();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [userName, setUserName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const [errorMessage, setErrorMessage] = useState("");
	const [firstNameError, setFirstNameError] = useState(false);
	const [lastNameError, setLastNameError] = useState(false);
	const [userNameError, setUserNameError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [, setUser] = useContext(UserContext);
	const [accessKey, setAccessKey] = useContext(AccessContext);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [defaultSnackbar] = useSnackbar();

	const submitRegistration = async (e) => {
		e.preventDefault();
		setLoading(true);
		setFirstNameError(false);
		setLastNameError(false);
		setUserNameError(false);
		setEmailError(false);
		setPasswordError(false);
		setErrorMessage("");
		if (validate() === true) {
			const requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					first_name: firstName,
					last_name: lastName,
					username: userName,
					email: email,
					password: password,
					access_key: accessKey,
				}),
			};

			const response = await fetch(
				`${process.env.REACT_APP_API_URI}/users/register`,
				requestOptions
			);
			const data = await response.json();

			if (!response.ok) {
				setErrorMessage(data.msg);
				if (data.type === "email") {
					setEmailError(true);
				} else {
					setUserNameError(true);
				}
			} else {
				setUser(JSON.stringify(data.user));
				setAccessKey("");
				navigate("/");
				successSnackbar("Account created.");
			}
		}
		setLoading(false);
	};

	const validate = () => {
		if (!firstName || !lastName || !userName || !email || !password) {
			setErrorMessage("Please fill out all required fields.");

			if (!firstName) {
				setFirstNameError(true);
			}
			if (!lastName) {
				setLastNameError(true);
			}
			if (!userName) {
				setUserNameError(true);
			}
			if (!email) {
				setEmailError(true);
			}
			if (!password) {
				setPasswordError(true);
			}

			return false;
		} else {
			return true;
		}
	};

	return (
		<>
			<Helmet>
				<title>Sign Up | Pokebuilder</title>
			</Helmet>
			<Section className="full-page flex-column">
				<Form
					className="form-card background-light rounded col-lg-4 col-md-6 col-11"
					onSubmit={submitRegistration}
				>
					<h1 className="login-title default-text bold-text title-lg align-center upper">
						Sign Up
					</h1>
					<Row>
						<Col>
							<label className="default-text bold-text">First Name</label>
							<Form.Control
								type="text"
								className={`login-input login-block default-text ${
									firstNameError && "error"
								}`}
								placeholder="Enter first name"
								onChange={(e) => setFirstName(e.target.value)}
							/>
						</Col>
						<Col>
							<label className="default-text bold-text">Last Name</label>
							<Form.Control
								type="text"
								className={`login-input login-block default-text ${
									lastNameError && "error"
								}`}
								placeholder="Enter last name"
								onChange={(e) => setLastName(e.target.value)}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<label className="default-text bold-text">Username</label>
							<Form.Control
								type="text"
								className={`login-input login-block default-text ${
									userNameError && "error"
								}`}
								placeholder="Enter username"
								onChange={(e) => setUserName(e.target.value)}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<label className="default-text bold-text">Email</label>
							<Form.Control
								type="email"
								className={`login-input login-block default-text ${
									emailError && "error"
								}`}
								placeholder="Enter email"
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<label className="default-text bold-text">Password</label>
							<Form.Control
								type="password"
								className={`login-input login-block default-text ${
									passwordError && "error"
								}`}
								placeholder="Enter password"
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Col>
					</Row>
					{errorMessage && (
						<Row>
							<Col>
								<span className="form-error-msg text-danger">
									{errorMessage}
								</span>
							</Col>
						</Row>
					)}
					<Button
						type="submit"
						className="login-block btn-yellow login-btn upper medium-text full"
						disabled={loading}
					>
						Sign Up
					</Button>
					<div className="align-center">
						<span className="default-text m-0">
							Already have an account?{" "}
							<Link className="bold-text" to="/login">
								Log In
							</Link>
						</span>
					</div>
				</Form>
			</Section>
		</>
	);
};

export default SignUp;
