import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { MentionsInput, Mention } from "react-mentions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentBody from "../components/CommentBody";
import ReplyArrow from "../components/ReplyArrow";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
	faCircleExclamation,
	faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

const Comments = ({
	comments,
	onComment,
	onReply,
	user,
	commentLoading,
	replyLoading,
	loginRedirect,
	comment,
	setComment,
	setCommentPlain,
	setMentions,
	replyContent,
	setReplyContent,
	setReplyContentPlain,
	setReplyMentions,
	parentId,
	setParentId,
}) => {
	const navigate = useNavigate();
	const [replyActive, setReplyActive] = useState(false);

	const fetchSuggestions = async (query) => {
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/suggestions?q=${query}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			return data.usernames;
		}
	};

	const shortenNumber = (n) => {
		if (n < 1e3) return n;
		if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
		if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
		if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
		if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
	};

	const getCommentAge = (created) => {
		let timeString;
		const now = new Date().getTime();
		const createdAt = new Date(created).getTime();
		const diff = now - createdAt;
		const diffInSeconds = Math.round(diff / 1000);
		const diffInMinutes = Math.round(diff / (1000 * 60));
		const diffInHours = Math.round(diff / (1000 * 60 * 60));

		if (diff < 86400000) {
			if (diffInSeconds < 60) {
				timeString = `${diffInSeconds} seconds ago`;
			} else if (diffInMinutes < 60) {
				timeString = `${diffInMinutes} minutes ago`;
			} else {
				timeString = `${diffInHours} hours ago`;
			}
		} else if (diff < 172800000) {
			timeString = "Yesterday - " + format(new Date(created), "hh:mm a");
		} else if (diff < 31536000000) {
			timeString = format(new Date(created), "MMM dd - hh:mm a");
		} else {
			timeString = format(new Date(created), "MMM dd, yyyy - hh:mm a");
		}
		return timeString;
	};

	const handleMentionChange = (ev, newValue, newPlainTextValue, mentions) => {
		setComment(newValue);
		setCommentPlain(newPlainTextValue);
		setMentions(mentions);
	};

	const handleReplyChange = (ev, newValue, newPlainTextValue, mentions) => {
		setReplyContent(newValue);
		setReplyContentPlain(newPlainTextValue);
		setReplyMentions(mentions);
	};

	const handleReply = (e, comment) => {
		e.preventDefault();
		if (user) {
			if (parentId === comment.id) {
				setParentId(null);
				setReplyContent("");
			} else {
				setParentId(comment.id);
			}
		} else {
			navigate(`/login/?r=${loginRedirect}`);
		}
	};

	return (
		<>
			{comments.length === 0 ? (
				<div className="comment">
					<span>No comments yet.</span>
				</div>
			) : (
				comments
					.filter((commentParent) => commentParent.comment.parent_id === null)
					.map((comment) => (
						<div key={comment.comment.id} className="comment-container">
							<div className="comment-content">
								<Row>
									<Col>
										<div key={comment.comment.id} className="comment">
											<div className="comment-header flex-row-left">
												<span className="comment-info comment-user bold-text default-text">
													{comment.comment.user.first_name}{" "}
													{comment.comment.user.last_name}
												</span>
												<span className="comment-info comment-username default-text opacity-5">
													@{comment.comment.user.username}
												</span>
												<div className="comment-info comment-ellipse background-default opacity-5" />
												<span className="comment-info comment-date default-text opacity-5">
													{getCommentAge(comment.comment.created_at)}
												</span>
											</div>
											<CommentBody comment={comment.comment} />
										</div>
									</Col>
								</Row>
								{comment.comment.children.length === 0 && (
									<Row className="comment-engagements">
										<Col className="flex-row-left">
											<a
												href="#"
												onClick={(e) => handleReply(e, comment.comment)}
												className="story-engagements-icon default-text medium-text text-sm"
											>
												<i className="ri-message-2-line" />
												<span className="comment-reply-label text-sm">
													Reply
												</span>
											</a>
										</Col>
									</Row>
								)}
								{comment.comment.children.length !== 0 &&
								comment.comment.id !== parentId ? (
									<a
										href="#"
										className="comment-replies-toggle default-text medium-text text-sm"
										onClick={(e) => handleReply(e, comment.comment)}
									>
										<ReplyArrow />
										View {shortenNumber(comment.comment.children.length)}{" "}
										{comment.comment.children.length === 1
											? "reply"
											: "replies"}
									</a>
								) : (
									<div className="comment-replies">
										{comment.comment.children.map((reply) => (
											<div
												key={reply.id}
												className="comment-container reply-container"
											>
												<div className="comment-content">
													<Row>
														<Col>
															<div className="comment-header flex-row-left">
																<span className="comment-info comment-user bold-text default-text">
																	{reply.user.first_name} {reply.user.last_name}
																</span>
																<span className="comment-info comment-username default-text opacity-5">
																	@{reply.user.username}
																</span>
																<div className="comment-info comment-ellipse background-default opacity-5" />
																<span className="comment-info comment-date default-text opacity-5">
																	{getCommentAge(reply.created_at)}
																</span>
															</div>
															<CommentBody comment={reply} />
														</Col>
													</Row>
													{/* <Row className="comment-engagements">
                                                        <Col className="flex-row-left">
                                                            <i className="story-engagements-icon ri-heart-line opacity-7 pointer" />
                                                            <span className="story-engagements-count text-sm opacity-7">
                                                                0
                                                            </span>
                                                        </Col>
                                                    </Row> */}
												</div>
											</div>
										))}
									</div>
								)}
							</div>
							{replyLoading && comment.comment.id === parentId && (
								<div className="flex-column full p-4">
									<Spinner
										as="span"
										animation="border"
										size="sm"
										className="text-active"
									/>
								</div>
							)}
							{comment.comment.id === parentId && (
								<div className="comment-container reply-form">
									<Form
										onSubmit={(e) => onReply(e)}
										className={`comment-form rounded ${
											replyActive && "active"
										}`}
									>
										<Row>
											<Col>
												<MentionsInput
													onChange={handleReplyChange}
													value={replyContent}
													onFocus={() => setReplyActive(true)}
													onBlur={() => setReplyActive(false)}
													className="comment-wrapper reply"
													placeholder="Write a reply"
													disabled={commentLoading}
													// onKeyDown={checkSubmit}
												>
													<Mention
														trigger="@"
														className="mentioned"
														data={(query, callback) => {
															fetchSuggestions(query).then(
																(filteredMentions) => {
																	callback(filteredMentions);
																}
															);
														}}
														renderSuggestion={(
															suggestion,
															search,
															highlightedDisplay,
															index,
															focused
														) => (
															<div
																className={`user-suggestions default-text ${
																	focused ? "focused" : ""
																}`}
															>
																{highlightedDisplay}
															</div>
														)}
													/>
												</MentionsInput>
												<Button
													type="submit"
													variant="primary"
													className="label-md reply-btn medium-text"
													disabled={replyLoading || !replyContent}
												>
													{/* {replyLoading ? "Posting" : "Post"} */}
													<FontAwesomeIcon
														icon={faPaperPlane}
														className="white-text"
													/>
												</Button>
											</Col>
										</Row>
									</Form>
								</div>
							)}
						</div>
					))
			)}
			{user ? (
				<Form className="comment-block" onSubmit={(e) => onComment(e)}>
					{/* <Form.Control
                    as="textarea"
                    className="comment-input rounded"
                    placeholder="Add Comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    disabled={commentLoading}
                    onKeyDown={checkSubmit}
                /> */}
					<MentionsInput
						onChange={handleMentionChange}
						value={comment}
						className="comment-wrapper mt-4 mb-10"
						placeholder="Add Comment"
						disabled={commentLoading}
						// onKeyDown={checkSubmit}
					>
						<Mention
							trigger="@"
							className="mentioned"
							data={(query, callback) => {
								fetchSuggestions(query).then((filteredMentions) => {
									callback(filteredMentions);
								});
							}}
							renderSuggestion={(
								suggestion,
								search,
								highlightedDisplay,
								index,
								focused
							) => (
								<div
									className={`user-suggestions default-text ${
										focused ? "focused" : ""
									}`}
								>
									{highlightedDisplay}
								</div>
							)}
						/>
					</MentionsInput>
					<div className="align-right">
						<Button
							type="submit"
							variant="primary"
							className="comment-btn medium-text"
							disabled={commentLoading || !comment}
						>
							{commentLoading ? "Posting" : "Post"}
						</Button>
					</div>
				</Form>
			) : (
				<div className="comment-login-container comment-block background-gray rounded flex-row-left">
					<FontAwesomeIcon
						icon={faCircleExclamation}
						className="comment-login-icon default-text"
					/>
					<span className="title-sm bold-text default-text">
						<a href="/login" className="decorated">
							Login
						</a>{" "}
						or{" "}
						<a href="/signup" className="decorated">
							Signup
						</a>{" "}
						to leave a comment.
					</span>
				</div>
			)}
		</>
	);
};

export default Comments;
