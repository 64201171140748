import React, { useState, useEffect, useContext, Suspense } from "react";
import { ClipboardContext } from "../context/ClipboardContext";
import { useParams } from "react-router";
import { useSnackbar } from "react-simple-snackbar";
import { Helmet } from "react-helmet";
import Loader from "../components/Loader";

const Set = () => {
	let { id } = useParams();
	const [clipboard, setClipboard] = useContext(ClipboardContext);
	const [set, fetchSet] = useState({});
	const [loading, setLoading] = useState(true);
	const [isLoaded, setIsLoaded] = useState(false);
	const [Template, setTemplate] = useState(null);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [defaultSnackbar] = useSnackbar();

	const getData = () => {
		fetch(`${process.env.REACT_APP_API_URI}/cards/sets/info/${id}`)
			.then((res) => res.json())
			.then((res) => {
				fetchSet(res.set);
				setLoading(false);
				setTemplate(
					React.lazy(() => import(`../set_templates/${res.set.template}`))
				);
			});
	};

	const copyCard = (card) => {
		if (clipboard.filter((existing) => existing.id == card.id).length != 0) {
			const newClipboard = [...clipboard];
			newClipboard.filter((existing) => existing.id == card.id)[0].qty += 1;
			setClipboard(newClipboard);
		} else {
			setClipboard((clipboard) => [
				...clipboard,
				{
					id: card.card_id,
					tcg_id: card.tcg_id,
					number: card.number,
					ptcgo_code: card.card_set.ptcgo_code,
					supertype: card.supertype,
					img: card.lg_img,
					name: card.name,
					qty: 1,
				},
			]);
		}
		defaultSnackbar("Added to clipboard.");
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			<Helmet>
				<title>{set?.name || "Sets"} | Pokebuilder</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : Template ? (
				<Suspense fallback={<Loader />}>
					<Template set={set} copyCard={copyCard} />
				</Suspense>
			) : (
				""
			)}
		</>
	);
};

export default Set;
