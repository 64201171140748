import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { AccessContext } from "../context/AccessContext";
import { useSnackbar } from "react-simple-snackbar";
import { Helmet } from "react-helmet";

import Section from "../components/Section";
import Container from "react-bootstrap/Container";
import Tabs from "../components/Tabs";
import TabItem from "../components/TabItem";
import TabContent from "../components/TabContent";
import TabPane from "../components/TabPane";
import Row from "../components/Row";
import Column from "../components/Column";
import Deck from "../components/Deck";
import Loader from "../components/Loader";
import EmptyTab from "../components/EmptyTab";
import BodyText from "../components/BodyText";

import Modal from "react-bootstrap/Modal";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import Hero from "../components/Hero";
import PokeSilhouette from "../components/PokeSilhouette";

const Home = () => {
	const [user] = useContext(UserContext);
	const navigate = useNavigate();
	const [accessKey, setAccessKey] = useContext(AccessContext);
	const [loading, setLoading] = useState(true);
	const [latest, fetchLatest] = useState([]);
	const [tab, setTab] = useState("latest");
	const [createDeckModal, setCreateDeckModal] = useState(false);
	const [pasteDeckModal, setPasteDeckModal] = useState(false);
	const [invalidCardsModal, setInvalidCardsModal] = useState(false);
	const [deckName, setDeckName] = useState("");
	const [desc, setDesc] = useState("");
	const [cards, setCards] = useState("");
	const [invalidCards, setInvalidCards] = useState([]);

	const mdParser = new MarkdownIt();

	const handleEditorChange = ({ html, text }) => {
		setDesc(text);
	};

	try {
		var user_obj = JSON.parse(user);
		var auth_token = user_obj.auth_token;
	} catch {
		var user_obj = null;
		var auth_token = null;
	}

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const createBlankDeck = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				access_key: accessKey,
				name: deckName,
				desc: desc,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/create`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.message);
		} else {
			if (data.access_key) {
				setAccessKey(data.access_key);
			}
			setCreateDeckModal(false);
			successSnackbar("Deck created.");
			navigate("/decks/" + data.deck_id);
		}
	};

	const pasteDeck = async (force = false) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				access_key: accessKey,
				name: deckName,
				cards: cards,
				force: force,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/paste`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.message);
		} else {
			if (data.invalid_cards) {
				setInvalidCards(data.invalid_cards);
				setInvalidCardsModal(true);
			} else {
				if (data.access_key) {
					setAccessKey(data.access_key);
				}
				setCreateDeckModal(false);
				successSnackbar("Deck created.");
				navigate("/decks/" + data.deck_id);
			}
		}
	};

	const getData = () => {
		setLoading(true);
		fetch(`${process.env.REACT_APP_API_URI}/decks/previews?tab=${tab}`)
			.then((res) => res.json())
			.then((res) => {
				fetchLatest(res.decks);
				setLoading(false);
			});
	};

	useEffect(() => {
		getData();
	}, [tab]);

	return (
		<div className="content">
			<Helmet>
				<title>
					Pokebuilder - Create, Share, and Explore Pokémon Decks and Cards
				</title>
			</Helmet>
			<Container>
				<Hero />
			</Container>
			<Container className="quick-links">
				<Row>
					<Column className="col-md-4 col-12">
						<Link to="/decks">
							<div className="quick-link decks flex-row-left rounded full">
								<h1 className="text-xl bold-text white-text upper mb-0">
									Decks
								</h1>
								<PokeSilhouette className="quick-link-pokeball decks" />
							</div>
						</Link>
					</Column>
					<Column className="col-md-4 col-12">
						<Link to="/cards">
							<div className="quick-link cards flex-row-left rounded full">
								<h1 className="text-xl bold-text white-text upper mb-0">
									Cards
								</h1>
								<PokeSilhouette className="quick-link-pokeball cards" />
							</div>
						</Link>
					</Column>
					<Column className="col-md-4 col-12">
						<Link to="/sets">
							<div className="quick-link sets flex-row-left rounded full">
								<h1 className="text-xl bold-text white-text upper mb-0">
									Sets
								</h1>
								<PokeSilhouette className="quick-link-pokeball sets" />
							</div>
						</Link>
					</Column>
				</Row>
			</Container>
			<Section>
				<Container>
					<Tabs className="home-nav-tabs background-default rounded flex-row-left">
						<TabItem
							className="white-text rounded"
							label="Latest"
							href="#latestTab"
							func={() => setTab("latest")}
							active
						/>
						<TabItem
							className="white-text rounded"
							label="Top"
							href="#topTab"
							func={() => setTab("top")}
						/>
						<TabItem
							className="white-text rounded"
							label="Standard"
							href="#standardTab"
							func={() => setTab("standard")}
						/>
						<TabItem
							className="white-text rounded"
							label="Expanded"
							href="#expandedTab"
							func={() => setTab("expanded")}
						/>
						<TabItem
							className="white-text rounded"
							label="Legacy"
							href="#legacyTab"
							func={() => setTab("legacy")}
						/>
					</Tabs>
				</Container>
			</Section>
			{loading ? (
				<Loader className="search-loader" />
			) : (
				<Container>
					{latest.length != 0 ? (
						<Row>
							{latest.map((deck) => (
								<Column
									className="col-lg-3 col-md-4 col-sm-6 col-12"
									key={deck.id}
								>
									<Deck
										deck={deck}
										user_id={user_obj ? user_obj.user_id : null}
									/>
								</Column>
							))}
						</Row>
					) : (
						<EmptyTab />
					)}
				</Container>
			)}
			<Modal show={createDeckModal} size="lg" centered>
				<Modal.Header>
					<Modal.Title className="default-text bold-text">
						Create Deck
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<Row>
						<Column>
							<label className="default-text">Name</label>
							<input
								type="text"
								className="form-control"
								onChange={(e) => setDeckName(e.target.value)}
								required
							/>
						</Column>
					</Row>
					<br />
					<Row>
						<Column>
							<label className="default-text">Description (Optional)</label>
							<MdEditor
								style={{ height: "500px" }}
								renderHTML={(text) => mdParser.render(text)}
								onChange={handleEditorChange}
							/>
						</Column>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<button
						type="button"
						className="btn btn-default modal-btn"
						onClick={() => setCreateDeckModal(false)}
					>
						Close
					</button>
					<button
						type="button"
						className="btn btn-primary modal-btn"
						onClick={() => createBlankDeck()}
					>
						Next
					</button>
				</Modal.Footer>
			</Modal>
			<Modal show={pasteDeckModal} size="lg" centered>
				<Modal.Header>
					<Modal.Title className="default-text bold-text">
						Paste Deck
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<Row>
						<Column>
							<label className="default-text">Name</label>
							<input
								type="text"
								className="form-control"
								onChange={(e) => setDeckName(e.target.value)}
								required
							/>
						</Column>
					</Row>
					<br />
					<Row>
						<Column>
							<label className="default-text">Cards</label>
							<textarea
								className="card-paste-textarea form-control"
								onChange={(e) => setCards(e.target.value)}
								required
							/>
						</Column>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<button
						type="button"
						className="btn btn-default modal-btn"
						onClick={() => setPasteDeckModal(false)}
					>
						Close
					</button>
					<button
						type="button"
						className="btn btn-primary modal-btn"
						onClick={() => pasteDeck()}
					>
						Next
					</button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={invalidCardsModal}
				onHide={() => setInvalidCardsModal(false)}
				className="invalid-cards-modal"
				centered
			>
				<Modal.Header>
					<Modal.Title className="default-text bold-text">
						Invalid Cards
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<BodyText>
						We couldn't find some of the cards in your list. This deck will be
						created without the following:
					</BodyText>
					<BodyText className="invalid-cards default-text">
						{invalidCards.map((invalidCard) => invalidCard).join(", ")}
					</BodyText>
				</Modal.Body>
				<Modal.Footer>
					<button
						type="button"
						className="btn btn-default modal-btn"
						onClick={() => setInvalidCardsModal(false)}
					>
						Cancel
					</button>
					<button
						type="button"
						className="btn btn-primary modal-btn"
						onClick={() => pasteDeck(true)}
					>
						Proceed
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default Home;
