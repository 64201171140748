import { useState, useEffect } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import placeholder from "../assets/card_placeholder.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHeart,
	faTriangleExclamation,
	faEye,
} from "@fortawesome/free-solid-svg-icons";

const Deck = ({ className, deck, user_id }) => {
	const [updatedDeck, setUpdatedDeck] = useState(deck);
	const [likes, setLikes] = useState(deck.likes.length);
	const [liked, setLiked] = useState(
		deck.likes.filter((existing) => existing.user_id == user_id).length != 0
	);

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const toggleLike = async (e) => {
		e.preventDefault();

		if (liked) {
			setLikes(likes - 1);
		} else {
			setLikes(likes + 1);
		}
		setLiked(!liked);

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				user_id: user_id,
				deck_id: updatedDeck.id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/like/toggle`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		}
	};

	const shortenNumber = (n) => {
		if (n < 1e3) return n;
		if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
		if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
		if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
		if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
	};

	return (
		<div className={`deck rounded ${className || ""}`}>
			<Link to={`/decks/${updatedDeck.id}`}>
				<div className="deck-content">
					<Container className="gx-0">
						<Row className="gx-2">
							<Col className="deck-main">
								<img
									src={updatedDeck.img || placeholder}
									alt="Deck photo 1"
									className="full"
								/>
							</Col>
							<Col>
								<Row className="gx-2 deck-sub-row top">
									<Col>
										<img
											src={updatedDeck.img1 || placeholder}
											alt="Deck photo 2"
											className=""
										/>
									</Col>
									<Col>
										<img
											src={updatedDeck.img2 || placeholder}
											alt="Deck photo 3"
											className=""
										/>
									</Col>
								</Row>
								<Row className="gx-2 deck-sub-row bottom">
									<Col>
										<img
											src={updatedDeck.img3 || placeholder}
											alt="Deck photo 4"
											className=""
										/>
									</Col>
									<Col>
										<img
											src={updatedDeck.img4 || placeholder}
											alt="Deck photo 5"
											className=""
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row></Row>
					</Container>
					<div className="deck-name">
						<span className="bold-text default-text">{updatedDeck.name}</span>
					</div>
					<div className="deck-tags">
						{deck.format && (
							<span className="deck-tag label-md default-text">
								{deck.format}
							</span>
						)}
						{!deck.is_valid && (
							<span className="deck-tag invalid label-md text-dark">
								<FontAwesomeIcon
									icon={faTriangleExclamation}
									className="invalid-icon"
								/>
								Invalid
							</span>
						)}
					</div>
				</div>
			</Link>
			<div className="deck-misc">
				<Row className="flex-row-left">
					<Col>
						<span className="label-md default-text">
							Uploaded {new Date(updatedDeck.created_at).toLocaleDateString()}
						</span>
					</Col>
					<Col className="flex-row-right">
						{user_id ? (
							<a
								href="#"
								className={`deck-preview-engagement-icon likes ${
									liked && "active"
								}`}
								onClick={toggleLike}
							>
								<FontAwesomeIcon icon={faHeart} />
							</a>
						) : (
							<Link
								to="/login"
								className={`deck-preview-engagement-icon likes ${
									liked && "active"
								}`}
							>
								<FontAwesomeIcon icon={faHeart} />
							</Link>
						)}
						<span className="text-sm default-text">{shortenNumber(likes)}</span>
						{/* <Link
							to={`/decks/${updatedDeck.id}`}
							className="deck-preview-engagement-icon comments"
						>
							<FontAwesomeIcon icon={faComment} />
						</Link>
						<span className="text-sm default-text">
							{shortenNumber(updatedDeck.deck_comments.length)}
						</span> */}
						<Link
							to={`/decks/${updatedDeck.id}`}
							className="deck-preview-engagement-icon comments"
						>
							<FontAwesomeIcon icon={faEye} />
						</Link>
						<span className="text-sm default-text">
							{shortenNumber(updatedDeck.view_count)}
						</span>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default Deck;
