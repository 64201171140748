import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { Helmet } from "react-helmet";
import Section from "../components/Section";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Loader from "../components/Loader";

const ResetPassword = () => {
	let { token } = useParams();
	const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [defaultSnackbar] = useSnackbar();

	const submitReset = async (e) => {
		e.preventDefault();
		setLoading(true);
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				password: password,
				token: token,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/password/reset`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data[0].msg);
		} else {
			navigate("/login");
			successSnackbar(data.msg);
		}
		setLoading(false);
	};

	return (
		<>
			<Helmet>
				<title>Reset Password | Pokebuilder</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : (
				<Section className="full-page flex-column">
					<Form
						className="form-card background-light rounded col-md-4"
						onSubmit={submitReset}
					>
						<h1 className="login-title default-text bold-text title-lg align-center upper">
							Reset Password
						</h1>
						<label className="default-text bold-text">New Password</label>
						<Form.Control
							type="password"
							className="login-input login-block default-text"
							placeholder="Enter new password"
							onChange={(e) => setPassword(e.target.value)}
							required
						/>
						<Button
							type="submit"
							className="login-block btn-yellow login-btn upper medium-text full"
						>
							Reset Password
						</Button>
					</Form>
				</Section>
			)}
		</>
	);
};

export default ResetPassword;
