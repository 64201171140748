import { Helmet } from "react-helmet";
import Section from "../components/Section";
import Container from "../components/Container";
import about from "../assets/about.png";

const About = () => {
	return (
		<>
			<Helmet>
				<title>About Us | Pokebuilder</title>
			</Helmet>
			<Section className="about-section">
				<Container className="flex-column">
					<h1 className="default-text mb-4 bold-text">What we do</h1>
					<p className="about-body default-text align-center">
						PokéBuilder is more than just a website; it's a labor of love that
						emerged from a deep-rooted passion for the Pokémon TCG and a burning
						desire to provide a solution to a problem we personally faced. As
						avid deck builders ourselves, we often found ourselves searching in
						vain for a platform that embraced the joy of crafting unique and
						unconventional decks. Frustrated by the lack of options, we embarked
						on a journey to create the very platform we had been longing for.
						PokéBuilder is the result – a space where our shared passion for
						deck building meets your longing for a dedicated platform that
						celebrates creativity, diversity, and the art of thinking outside
						the box. Join us on this extraordinary journey as we transform the
						way you build and experience Pokémon TCG decks, one card at a time.
					</p>
					<h1 className="default-text mt-5 mb-4 bold-text">Key features:</h1>
					<ol>
						<li className="mb-3 about-body default-text">
							Card Browser: Dive into our extensive database of Pokémon TCG
							cards and unlock a treasure trove of information. From classic
							cards to the latest expansions, you can easily search, filter, and
							explore cards based on various criteria such as type, rarity, set,
							and more. Immerse yourself in the rich artwork, abilities, and
							stats of each card as you embark on your Pokémon TCG journey.
						</li>
						<li className="mb-3 about-body default-text">
							Deck Builder: Unleash your creativity and construct powerful decks
							using our intuitive deck-building tool. Experiment with different
							combinations, strategize your gameplay, and fine-tune your deck to
							perfection. Planning your dream deck has never been easier.
						</li>
						<li className="mb-3 about-body default-text">
							Community Interaction: Engage with fellow Pokémon TCG enthusiasts
							in our vibrant community. Join discussions, share insights, and
							exchange strategies about decks, cards, and gameplay. Debate on
							the diverse perspectives of the community.
						</li>
						<li className="mb-3 about-body default-text">
							Save and Share: Seamlessly save and organize your created decks on
							our platform and easily share your deck creations with friends,
							fellow players, or on social media platforms to showcase your
							creativity and inspire others.
						</li>
					</ol>
					<img
						src={about}
						alt="About page graphics"
						className="about-img mt-4"
					/>
				</Container>
			</Section>
		</>
	);
};

export default About;
