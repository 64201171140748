import ReactTooltip from "react-tooltip";

const Info = ({ className, fill }) => {
	return (
		<>
			<ReactTooltip />
			<div
				data-html={true}
				data-effect="solid"
				data-place="bottom"
				data-class="info-tooltip white-text"
				data-type="dark"
				data-delay-show="0"
				data-tip="<span>Copy from your card clipboard</span>"
			>
				<svg
					className={className}
					width="17"
					height="17"
					viewBox="0 0 17 17"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8 7.78906L8.02733 7.77573C8.11282 7.73302 8.20875 7.71571 8.30378 7.72583C8.3988 7.73596 8.48893 7.77311 8.5635 7.83287C8.63806 7.89263 8.69394 7.97252 8.72451 8.06305C8.75508 8.15359 8.75907 8.251 8.736 8.34373L8.264 10.2344C8.24076 10.3272 8.24463 10.4247 8.27513 10.5153C8.30563 10.606 8.36149 10.686 8.43609 10.7458C8.51069 10.8057 8.60089 10.8429 8.696 10.853C8.7911 10.8632 8.88712 10.8458 8.97267 10.8031L9 10.7891M14.5 8.28906C14.5 9.07699 14.3448 9.85721 14.0433 10.5852C13.7417 11.3131 13.2998 11.9746 12.7426 12.5317C12.1855 13.0889 11.5241 13.5308 10.7961 13.8323C10.0681 14.1339 9.28793 14.2891 8.5 14.2891C7.71207 14.2891 6.93185 14.1339 6.2039 13.8323C5.47595 13.5308 4.81451 13.0889 4.25736 12.5317C3.70021 11.9746 3.25825 11.3131 2.95672 10.5852C2.65519 9.85721 2.5 9.07699 2.5 8.28906C2.5 6.69776 3.13214 5.17164 4.25736 4.04642C5.38258 2.9212 6.9087 2.28906 8.5 2.28906C10.0913 2.28906 11.6174 2.9212 12.7426 4.04642C13.8679 5.17164 14.5 6.69776 14.5 8.28906ZM8.5 5.78906H8.50533V5.7944H8.5V5.78906Z"
						stroke={fill || "white"}
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>
		</>
	);
};

export default Info;
