const CommentBody = ({ comment }) => {
	const renderContentWithMentions = () => {
		// Split the comment content into an array of words
		const words = comment.text.split(" ");

		// Map each word and check if it's a mentioned username
		return words.map((word, index) => {
			const mentionedUser = comment.mentions.find(
				(mention) => mention.name === word.replace(/^[^\w\s]+|[^\w\s]+$/g, "")
			);

			if (mentionedUser) {
				// If the word is a mentioned username, apply a different font color
				return (
					<span key={index} className="mention-display medium-text">
						@{mentionedUser.name}
					</span>
				);
			} else {
				// If it's not a mentioned username, render the word as it is
				return <span key={index}>{word} </span>;
			}
		});
	};

	return (
		<p className="comment-body dark-text mb-1">{renderContentWithMentions()}</p>
	);
};

export default CommentBody;
