import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight,
	faSearch,
} from "@fortawesome/free-solid-svg-icons";

import Tabs from "../components/Tabs";
import TabItem from "../components/TabItem";
import TabContent from "../components/TabContent";
import TabPane from "../components/TabPane";
import Row from "./Row";
import Column from "./Column";
import Loader from "../components/Loader";
import Container from "./Container";
import Card from "./Card";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

const AddCardsForm = ({
	id,
	fetchDeck,
	setPokemonCards,
	setEnergyCards,
	setTrainerCards,
	isVisible,
	setAddCardsModal,
}) => {
	const [cards, setCards] = useState("");
	const user = JSON.parse(localStorage.getItem("pokebuilderUser"));
	const [searching, setSearching] = useState(false);

	const [cardResults, fetchCardResults] = useState([]);
	const [meta, fetchMeta] = useState([]);
	const [items, fetchItems] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [selectedItem, setSelectedItem] = useState(null);
	const [rawSelected, setRawSelected] = useState(true);
	const [name, setName] = useState(searchParams.get("q") || "");
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [loadingSuggestions, setLoadingSuggestions] = useState(false);
	const [fromSuggestions, setFromSuggestions] = useState(false);
	const [rawName, setRawName] = useState("");
	const [searchFocused, setSearchFocused] = useState(false);
	const [searchType, setSearchType] = useState(
		searchParams.get("searchType") || "name"
	);
	const [formatFilter, setFormatFilter] = useState(
		searchParams.get("format") ? searchParams.get("format") : ""
	);

	const searchCards = () => {
		fetch(`${process.env.REACT_APP_API_URI}/cards/${window.location.search}`)
			.then((res) => res.json())
			.then((res) => {
				fetchCardResults(res.data);
				fetchMeta(res.meta);
				setSearching(false);
			});
	};

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [defaultSnackbar] = useSnackbar();

	const handleSearchSelect = (e, item_name) => {
		e.preventDefault();
		setShowSuggestions(false);
		setRawSelected(true);
		if (item_name) {
			setName(item_name);
			setSearchParams({
				page: 1,
				q: item_name,
			});
		}
	};

	const getSuggestions = () => {
		setLoadingSuggestions(true);
		fetch(`${process.env.REACT_APP_API_URI}/cards/suggestions?q=${name}`)
			.then((res) => res.json())
			.then((res) => {
				setSelectedItem(null);
				fetchItems(res.data);
				setLoadingSuggestions(false);
			});
	};

	const handleSearchType = (type) => {
		setSearchType(type);
		if (name) {
			setSearchParams({
				page: 1,
				q: name,
				searchType: type,
			});
		}
	};

	const highlightSuggestion = (e) => {
		if (e.keyCode === 40) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem < items.length - 1) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem + 1);
				} else {
					if (rawSelected) {
						setSelectedItem(0);
						setRawSelected(false);
					} else {
						setSelectedItem(null);
						setRawSelected(true);
					}
				}
			} else {
				setRawSelected(!rawSelected);
			}
		} else if (e.keyCode === 38) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem !== 0) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem - 1);
				} else {
					if (selectedItem === 0) {
						setSelectedItem(null);
						setRawSelected(true);
					} else {
						setSelectedItem(items.length - 1);
						setRawSelected(false);
					}
				}
			} else {
				setRawSelected(!rawSelected);
			}
		} else {
			setFromSuggestions(false);
		}
	};

	const handleSuggestionHover = () => {
		setRawSelected(false);
		setFromSuggestions(true);
		setSelectedItem(null);
	};

	const updateDeck = (id) => {
		console.log(id);
		fetch(`${process.env.REACT_APP_API_URI}/decks/info/${id}`)
			.then((res) => res.json())
			.then((res) => {
				fetchDeck(res.deck);
				setPokemonCards(
					res.deck.deck_cards
						.filter((deck_card) => deck_card.card.supertype == "Pokémon")
						.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
				);
				setEnergyCards(
					res.deck.deck_cards
						.filter((deck_card) => deck_card.card.supertype == "Energy")
						.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
				);
				setTrainerCards(
					res.deck.deck_cards
						.filter((deck_card) => deck_card.card.supertype == "Trainer")
						.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
				);
			});
	};

	const pasteCards = async (e) => {
		e.preventDefault();
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				deck_id: `${id}`,
				cards: cards,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/add_cards`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.message);
		} else {
			updateDeck(data.deck_id);
			handleClose();
			successSnackbar("Cards added.");
		}
	};

	const addCard = async (card) => {
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				deck_id: `${id}`,
				card_id: card.id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/add_card`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.message);
		} else {
			updateDeck(data.deck.id);
			successSnackbar("Card added.");
		}
	};

	const nextPage = () => {
		setSearchParams({
			page: searchParams.get("page")
				? parseInt(searchParams.get("page")) + 1
				: 2,
			q: name,
			searchType: searchType,
		});
	};

	const previousPage = () => {
		setSearchParams({
			page: parseInt(searchParams.get("page")) - 1,
			q: name,
			searchType: searchType,
		});
	};

	const handleFilter = (e) => {
		e.preventDefault();
		setSearchParams({
			page: 1,
			q: name,
			searchType: searchType,
		});
		setShowSuggestions(false);
	};

	const handleName = (name) => {
		setName(name);
		setRawName(name);
	};

	useEffect(() => {
		setSearching(true);
		searchCards();
	}, [searchParams]);

	useEffect(() => {
		if (selectedItem !== null) {
			setName(items[selectedItem].name);
		} else {
			setName(rawName);
		}
	}, [selectedItem]);

	useEffect(() => {
		if (!fromSuggestions) {
			if (name !== "" && searchFocused) {
				setShowSuggestions(true);
				setLoadingSuggestions(true);
				const timer = setTimeout(() => {
					getSuggestions();
				}, 500);
				return () => clearTimeout(timer);
			} else {
				setShowSuggestions(false);
				setLoadingSuggestions(true);
			}
		}
	}, [name]);

	const handleClose = () => {
		setName("");
		setSearchParams({});
		setAddCardsModal(false);
	};

	return (
		<Modal
			show={isVisible}
			onHide={handleClose}
			size="lg"
			className="add-cards-modal"
			centered
		>
			<Modal.Header>
				<Modal.Title className="default-text bold-text">Add Cards</Modal.Title>
			</Modal.Header>
			<Modal.Body className="pt-4 pb-4">
				<Tabs className="default-text">
					<TabItem label="Search Cards" href="#searchCardsTab" active />
					<TabItem label="Paste Cards" href="#pasteCardsTab" />
				</Tabs>
				<TabContent className="mt-3">
					<TabPane id="searchCardsTab" active>
						<div className="card-filters main flex-row-left mb-3">
							<div className="card-filters-row col-12 flex-row-left">
								<div className="search-container card-search">
									<Form onSubmit={handleFilter}>
										<input
											value={name}
											className="form-control search-text"
											placeholder={`Search card ${searchType}`}
											type="text"
											onChange={(e) => handleName(e.target.value)}
											onKeyDown={highlightSuggestion}
											onFocus={() => setSearchFocused(true)}
											// onBlur={handleSearchBlur}
										/>
										<select
											className="search-toggle white-text bold-text"
											onChange={(e) => handleSearchType(e.target.value)}
											defaultValue={searchType == "name" ? "name" : "text"}
										>
											<option value="name">Name</option>
											<option value="text">Card Text</option>
										</select>
										<div className="search-input-icon cards flex-row-right">
											<FontAwesomeIcon icon={faSearch} />
										</div>
										<input type="submit" className="hidden" />
									</Form>
									<div
										className={`suggestions-container background-white ${
											showSuggestions ? "" : "hidden"
										}`}
									>
										{name && (
											<a
												href="#"
												className={`raw-search-result search-suggestion default-text ellipsis ${
													rawSelected && "selected"
												}`}
												onMouseOver={handleSuggestionHover}
												onClick={(e) => handleSearchSelect(e, rawName)}
											>
												<FontAwesomeIcon icon={faSearch} className="me-2" />
												Search for "{rawName}"
											</a>
										)}
										{!loadingSuggestions ? (
											items.map((item, index) => (
												<a
													href="#"
													key={index}
													onMouseOver={handleSuggestionHover}
													onClick={(e) => handleSearchSelect(e, item.name)}
													className={`search-suggestion default-text ellipsis ${
														selectedItem === index ? "selected" : ""
													}`}
												>
													<div className="flex-row-left">
														<div className="suggestion-info">
															<span className="suggestion-name bold-text m-0">
																{item.name}
															</span>
														</div>
													</div>
												</a>
											))
										) : (
											<div className="suggestions-loader flex-column">
												<Spinner
													as="span"
													animation="border"
													size="sm"
													className="default-text"
												/>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
						{searching ? (
							<Loader className="search-loader" />
						) : (
							<Container type="fluid">
								<Row>
									{cardResults.length != 0
										? cardResults.map((card) => (
												<Card
													key={card.id}
													card={card}
													className="col-lg-4"
													func={addCard}
													type="add"
												/>
										  ))
										: ""}
								</Row>
							</Container>
						)}
					</TabPane>
					<TabPane id="pasteCardsTab">
						<form id="addCardsForm" onSubmit={pasteCards}>
							<Row>
								<Column>
									<textarea
										className="add-cards-paste form-control"
										placeholder="Paste your cards here"
										onChange={(e) => setCards(e.target.value)}
									/>
								</Column>
							</Row>
							<button
								type="submit"
								className="btn btn-primary modal-btn mt-3 pull-right"
							>
								Add Cards
							</button>
						</form>
					</TabPane>
				</TabContent>
				<br />
			</Modal.Body>
		</Modal>
	);
};

export default AddCardsForm;
