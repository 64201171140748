import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { AccessContext } from "../context/AccessContext";
import { ClipboardContext } from "../context/ClipboardContext";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { useSnackbar } from "react-simple-snackbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

import Loader from "../components/Loader";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tiles from "../components/Tiles";
import Copy from "../components/Copy";
import Comments from "../components/Comments";

const CardInfo = () => {
	const navigate = useNavigate();
	const [clipboard, setClipboard] = useContext(ClipboardContext);
	const [loading, setLoading] = useState(true);
	const [user] = useContext(UserContext);
	const [accessKey, setAccessKey] = useContext(AccessContext);
	const [newDeckName, setNewDeckName] = useState("");
	const [quickCreate, setQuickCreate] = useState(false);
	const [card, fetchCard] = useState([]);
	const [cardComments, fetchCardComments] = useState([]);
	const [tags, setTags] = useState([]);
	const [userDecks, fetchUserDecks] = useState([]);
	const [comment, setComment] = useState("");
	const [commentLoading, setCommentLoading] = useState(false);
	const [myDecksModal, setMyDecksModal] = useState(false);
	const [parentId, setParentId] = useState(null);
	const [replyLoading, setReplyLoading] = useState(false);
	const [replyContent, setReplyContent] = useState("");
	const [commentPlain, setCommentPlain] = useState("");
	const [replyContentPlain, setReplyContentPlain] = useState("");
	const [mentions, setMentions] = useState([]);
	const [replyMentions, setReplyMentions] = useState([]);

	let { id } = useParams();

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [defaultSnackbar] = useSnackbar();

	try {
		var auth_token = JSON.parse(user).auth_token;
		var user_id = JSON.parse(user).user_id;
	} catch {
		var auth_token = null;
		var user_id = null;
	}

	const requestOptions = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			"X-Auth-Token": auth_token,
			"X-Access-Key": accessKey,
		},
	};

	const addDeckCard = async (deckID) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				deck_id: deckID,
				card_id: card.id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/add_card`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.message);
		} else {
			setMyDecksModal(false);
			successSnackbar("Added to " + data.deck.name);
		}
	};

	const getData = () => {
		fetch(`${process.env.REACT_APP_API_URI}/cards/info/${id}`, requestOptions)
			.then((res) => res.json())
			.then((res) => {
				fetchCard(res.card);
				fetchCardComments(res.comments);
				setTags(res.tags);
				fetchUserDecks(res.user_decks);
				setLoading(false);
			});
	};

	const getCommentAge = (created) => {
		let timeString;
		const now = new Date().getTime();
		const createdAt = new Date(created).getTime();
		const diff = now - createdAt;
		const diffInSeconds = Math.round(diff / 1000);
		const diffInMinutes = Math.round(diff / (1000 * 60));
		const diffInHours = Math.round(diff / (1000 * 60 * 60));

		if (diff < 86400000) {
			if (diffInSeconds < 60) {
				timeString = `${diffInSeconds} seconds ago`;
			} else if (diffInMinutes < 60) {
				timeString = `${diffInMinutes} minutes ago`;
			} else {
				timeString = `${diffInHours} hours ago`;
			}
		} else if (diff < 172800000) {
			timeString = "Yesterday - " + format(new Date(created), "hh:mm a");
		} else if (diff < 31536000000) {
			timeString = format(new Date(created), "MMM dd - hh:mm a");
		} else {
			timeString = format(new Date(created), "MMM dd, yyyy - hh:mm a");
		}
		return timeString;
	};

	const resetForm = (formID) => {
		document.getElementById(formID).reset();
	};

	const KeyCodes = {
		comma: 188,
		enter: 13,
	};

	const delimiters = [KeyCodes.comma, KeyCodes.enter];

	const handleDelete = async (i) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				card_tag_id: tags[i].card_tag_id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/cards/delete_tag`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setTags(tags.filter((tag, index) => index !== i));
		}
	};

	const handleAddition = async (tag) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				card_id: card.id,
				tag: tag.text,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/cards/add_tag`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setTags([...tags, data]);
			console.log(tags);
		}
	};

	const handleDrag = (tag, currPos, newPos) => {
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		// re-render
		setTags(newTags);
	};

	const handleTagClick = (index) => {
		console.log("The tag at index " + index + " was clicked");
		navigate(`/cards?q=${tags[index].text}&page=1`);
	};

	const engageComment = async (url, commentID) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				comment_id: commentID,
				user_id: user_id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/cards/comments/${url}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			fetchCardComments(data.comments);
		}
	};

	const copyCard = (card) => {
		if (clipboard.filter((existing) => existing.id == card.id).length != 0) {
			const newClipboard = [...clipboard];
			newClipboard.filter((existing) => existing.id == card.id)[0].qty += 1;
			setClipboard(newClipboard);
		} else {
			setClipboard((clipboard) => [
				...clipboard,
				{
					id: card.id,
					tcg_id: card.tcg_id,
					number: card.number,
					ptcgo_code: (card.card_set && card.card_set.ptcgo_code) || "",
					supertype: card.supertype,
					img: card.lg_img,
					name: card.name,
					qty: 1,
				},
			]);
		}
		defaultSnackbar("Added to clipboard.");
	};

	const quickCreateDeck = async (e) => {
		e.preventDefault();
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				access_key: accessKey,
				name: newDeckName,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/create/quick`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.message);
		} else {
			setQuickCreate(false);
			if (data.access_key) {
				setAccessKey(data.access_key);
			}
			successSnackbar("Deck created.");
			fetchUserDecks(data.user_decks);
		}
	};

	const addComment = async (e) => {
		e.preventDefault();
		setCommentLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				comment: commentPlain,
				card_id: card.id,
				mentions: mentions,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/cards/comments/add`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong");
		} else {
			fetchCardComments(data.comments);
			setComment("");
			setCommentPlain("");
			setMentions([]);
			successSnackbar("Comment posted.");
		}
		setCommentLoading(false);
	};

	const addReply = async (e) => {
		e.preventDefault();
		setReplyLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				comment: replyContentPlain,
				card_id: card.id,
				parent_id: parentId,
				mentions: replyMentions,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/cards/comments/add`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong");
		} else {
			fetchCardComments(data.comments);
			setReplyContent("");
			setReplyContentPlain("");
			setReplyMentions([]);
			successSnackbar("Reply posted.");
		}
		setReplyLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			<Helmet>
				<title>
					{`${card?.name} - ${card?.card_set?.ptcgo_code} ${card?.number}` ||
						"Cards"}{" "}
					| Pokebuilder
				</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : (
				<>
					<div className="content full-page">
						<Container>
							<div className="page-content-header card-info-header flex-row-left background-default rounded">
								<Button
									className="card-info-header-btn rounded flex-row-center btn-yellow upper default-text medium-text"
									onClick={() => setMyDecksModal(true)}
								>
									<Tiles className="btn-tiles" />
									Add to Deck
								</Button>
								<Button
									className="card-info-header-btn rounded flex-row-center btn-purple upper default-text medium-text"
									onClick={() => copyCard(card)}
								>
									<Copy className="btn-copy" />
									Add Card to Clipboard
								</Button>
							</div>
							<Row>
								<Col className="col-lg-4 col-12">
									<div className="card-info background-light rounded">
										<div className="card-info-img">
											<img
												src={card.lg_img}
												alt="Card photo"
												className="full"
											/>
										</div>
										<div className="card-info-prices background-gray">
											<Row className="card-info-price">
												<Col className="border-bottom">
													<span className="default-text bold-text">Low</span>
												</Col>
												<Col className="align-right border-bottom">
													<span className="default-text">
														${card.low_price}
													</span>
												</Col>
											</Row>
											<Row className="card-info-price">
												<Col className="border-bottom">
													<span className="default-text bold-text">Mid</span>
												</Col>
												<Col className="align-right border-bottom">
													<span className="default-text">
														${card.mid_price}
													</span>
												</Col>
											</Row>
											<Row className="card-info-price">
												<Col className="border-bottom">
													<span className="default-text bold-text">High</span>
												</Col>
												<Col className="align-right border-bottom">
													<span className="default-text">
														${card.high_price}
													</span>
												</Col>
											</Row>
											<Row className="card-info-price">
												<Col>
													<span className="default-text bold-text">Market</span>
												</Col>
												<Col className="align-right">
													<span className="default-text">
														${card.market_price}
													</span>
												</Col>
											</Row>
										</div>
									</div>
								</Col>
								<Col className="col-lg-4 col-12">
									<div className="card-attr overflow-hidden rounded background-light">
										<div className="card-attr-header background-default">
											<h1 className="white-text bold-text title-sm mb-0">
												{card.name}
											</h1>
										</div>
										<div className="card-attr-body">
											<div className="card-attr-blocks">
												<Row className="gx-2">
													<Col>
														<div className="card-attr-block rounded background-gray align-center">
															<span className="card-attr-block-title default-text text-sm upper">
																Type
															</span>
															<span className="card-attr-block-data default-text bold-text align-center">
																{card.subtypes.map(
																	(subtype) => subtype.subtype.name + " "
																)}
																{card.supertype}
															</span>
														</div>
													</Col>
													<Col>
														<div className="card-attr-block rounded background-gray align-center">
															<span className="card-attr-block-title default-text text-sm upper">
																HP
															</span>
															<span className="card-attr-block-data default-text bold-text align-center">
																{card.hp}
															</span>
														</div>
													</Col>
													<Col>
														<div className="card-attr-block rounded background-gray align-center">
															<span className="card-attr-block-title default-text text-sm upper">
																ENERGY
															</span>
															<span className="card-attr-block-data default-text bold-text align-center">
																{card.types.map((type) => (
																	<div
																		key={type.id}
																		className="flex-row-center"
																	>
																		<img
																			key={type.id}
																			src={type.type.img}
																			alt="Card type symbol"
																			className="card-attr-icon"
																		/>
																		<span className="card-attr-type-name">
																			{type.type.name}
																		</span>
																	</div>
																))}
															</span>
														</div>
													</Col>
												</Row>
											</div>
											<div className="card-attr-details">
												{card.evolves_to.length != 0 &&
													card.evolves_to.map((evolves_to, index) => (
														<Row key={index} className="card-attr-detail">
															<Col
																className={`${
																	card.evolves_from && "border-bottom"
																}`}
															>
																<span className="default-text bold-text">
																	Evolves To:
																</span>
															</Col>
															<Col
																className={`align-right ${
																	card.evolves_from && "border-bottom"
																}`}
															>
																<Link
																	to={`/cards?q=${evolves_to.name}&page=1`}
																	className="default-text decorated"
																>
																	{evolves_to.name}
																	{index != card.evolves_to.length - 1
																		? ", "
																		: ""}
																</Link>
															</Col>
														</Row>
													))}
												{card.evolves_from && card.card_set != "" && (
													<Row className="card-attr-detail">
														<Col>
															<span className="default-text bold-text">
																Evolves From:
															</span>
														</Col>
														<Col className="align-right">
															<Link
																to={`/cards?q=${card.evolves_from}&page=1`}
																className="default-text decorated"
															>
																{card.evolves_from}
															</Link>
														</Col>
													</Row>
												)}
											</div>
										</div>
									</div>
									{card.attacks && card.attacks.length !== 0 && (
										<div className="card-attr overflow-hidden rounded background-light">
											<div className="card-attr-header background-default">
												<span className="white-text bold-text title-sm">
													Attacks
												</span>
											</div>
											<div className="card-attr-body padded">
												{card.attacks.map((attack) => (
													<div key={attack.id} className="card-attacks">
														<Row className="flex-row-left">
															<Col className="col-8">
																<div className="flex-row-left">
																	{attack.costs.map((cost) => (
																		<img
																			key={cost.id}
																			src={cost.type.img}
																			alt="Card type symbol"
																			className="card-attr-icon attack-icon"
																		/>
																	))}
																	<span className="card-attack-name default-text title-sm m-0">
																		{attack.name}
																	</span>
																</div>
															</Col>
															<Col className="col-4">
																<span className="default-text medium-text title-sm pull-right m-0">
																	{attack.damage.length <= 10
																		? attack.damage
																		: ""}
																</span>
															</Col>
														</Row>
														{attack.text || attack.damage.length > 10 ? (
															<Row className="card-attack-text">
																<Col>
																	{attack.damage.length > 10 ? (
																		<span className="default-text mt-2">
																			{attack.text}
																		</span>
																	) : (
																		""
																	)}
																	<span className="default-text mt-2">
																		{attack.text}
																	</span>
																</Col>
															</Row>
														) : (
															""
														)}
													</div>
												))}
											</div>
										</div>
									)}
									{card.rules && card.rules.length !== 0 && (
										<div className="card-attr overflow-hidden rounded background-light">
											<div className="card-attr-header background-default">
												<span className="white-text bold-text title-sm">
													Rules
												</span>
											</div>
											<div className="card-attr-body padded">
												{card.rules.map((rule) => (
													<div key={rule.id} className="card-attacks">
														<Row>
															<Col>
																<span className="default-text">
																	{rule.text}
																</span>
															</Col>
														</Row>
													</div>
												))}
											</div>
										</div>
									)}
									{card.supertype === "Pokémon" && (
										<div className="card-attr overflow-hidden rounded background-light">
											<div className="card-attr-blocks padded">
												<Row className="gx-2">
													<Col>
														<div className="card-attr-block rounded background-gray flex-column">
															<span className="card-attr-block-title default-text text-sm upper">
																Weakness
															</span>
															<div className="card-misc flex-row-center">
																{card.weaknesses.map((weakness) => (
																	<div
																		key={weakness.id}
																		className="flex-row-center"
																	>
																		<img
																			src={weakness.type.img}
																			alt="Card type symbol"
																			className="card-attr-icon me-1"
																		/>
																		<span className="default-text m-0">
																			{weakness.value}
																		</span>
																	</div>
																))}
															</div>
														</div>
													</Col>
													<Col>
														<div className="card-attr-block rounded background-gray flex-column">
															<span className="card-attr-block-title default-text text-sm upper">
																Resistance
															</span>
															<div className="card-misc flex-row-center">
																{card.resistances.map((resistance) => (
																	<div
																		key={resistance.id}
																		className="flex-row-center"
																	>
																		<img
																			src={resistance.type.img}
																			alt="Card type symbol"
																			className="card-attr-icon me-1"
																		/>
																		<span className="default-text m-0">
																			{resistance.value}
																		</span>
																	</div>
																))}
															</div>
														</div>
													</Col>
													<Col>
														<div className="card-attr-block rounded background-gray flex-column">
															<span className="card-attr-block-title default-text text-sm upper">
																Retreat Cost
															</span>
															<div className="card-misc flex-row-center">
																{card.retreat_costs.map((retreat_cost) => (
																	<div
																		key={retreat_cost.id}
																		className="flex-row-center"
																	>
																		<img
																			src={retreat_cost.type.img}
																			alt="Card type symbol"
																			className="card-attr-icon me-1"
																		/>
																		<span className="default-text m-0">
																			{retreat_cost.value}
																		</span>
																	</div>
																))}
															</div>
														</div>
													</Col>
												</Row>
											</div>
										</div>
									)}
								</Col>
								<Col className="col-lg-4 col-12">
									<div className="card-info background-light rounded">
										<div className="card-attr-header background-default">
											<span className="white-text bold-text title-sm">Set</span>
										</div>
										<div className="card-info-set-img flex-column">
											<img src={card.card_set.logo} alt="Set logo" />
										</div>
										<div className="card-info-prices background-gray">
											<Row className="card-info-price">
												<Col className="border-bottom">
													<span className="default-text bold-text">
														Set Name:
													</span>
												</Col>
												<Col className="align-right border-bottom">
													<Link
														to={`/sets/${card.card_set.id}`}
														className="default-text decorated"
													>
														{card.card_set.name}
													</Link>
												</Col>
											</Row>
											<Row className="card-info-price">
												<Col className="border-bottom">
													<span className="default-text bold-text">
														Release Date:
													</span>
												</Col>
												<Col className="align-right border-bottom">
													<span className="default-text">
														{new Date(
															card.card_set.release_date
														).toLocaleDateString()}
													</span>
												</Col>
											</Row>
											<Row className="card-info-price">
												<Col className="border-bottom">
													<span className="default-text bold-text">
														Number:
													</span>
												</Col>
												<Col className="align-right border-bottom">
													<span className="default-text">
														({card.number}/{card.card_set.total})
													</span>
												</Col>
											</Row>
											<Row className="card-info-price">
												<Col className="border-bottom">
													<span className="default-text bold-text">
														Rarity:
													</span>
												</Col>
												<Col className="align-right border-bottom">
													<span className="default-text">{card.rarity}</span>
												</Col>
											</Row>
											<Row className="card-info-price">
												<Col>
													<span className="default-text bold-text">
														Artist:
													</span>
												</Col>
												<Col className="align-right">
													<span className="default-text">{card.artist}</span>
												</Col>
											</Row>
											<div className="card-info-btn-container">
												<Link to={`/sets/${card.card_set.id}`}>
													<Button className="card-info-gallery-btn full rounded btn-yellow upper medium-text">
														See Card Gallery
													</Button>
												</Link>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
					<div className="comments-section background-light">
						<Container className="flex-row-center">
							<div className="col-lg-10 col-12">
								<Row>
									<Col className="col-lg-8 col-12">
										<div className="comments-container background-white rounded">
											<h2 className="comment-block text-xl bold-text default-text">
												Comments
											</h2>
											<Comments
												comments={cardComments}
												onComment={addComment}
												onReply={addReply}
												user={user}
												commentLoading={commentLoading}
												replyLoading={replyLoading}
												loginRedirect={`/cards/${card.id}`}
												comment={comment}
												setComment={setComment}
												setCommentPlain={setCommentPlain}
												setMentions={setMentions}
												replyContent={replyContent}
												setReplyContent={setReplyContent}
												setReplyContentPlain={setReplyContentPlain}
												setReplyMentions={setReplyMentions}
												parentId={parentId}
												setParentId={setParentId}
											/>
										</div>
									</Col>
									<Col className="col-lg-4 col-12">
										<div className="tags-container background-white rounded">
											<h2 className="tag-block text-xl bold-text default-text">
												Tags
											</h2>
										</div>
									</Col>
								</Row>
							</div>
						</Container>
					</div>
					<Modal
						show={myDecksModal}
						size="lg"
						onHide={() => setMyDecksModal(false)}
						centered
					>
						<Modal.Header>
							<Modal.Title className="default-text bold-text">
								My Decks
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className="pt-4 pb-4">
							{quickCreate ? (
								<form className="user-deck no-hover" onSubmit={quickCreateDeck}>
									<input
										type="text"
										className="form-control"
										placeholder="New deck name"
										onChange={(e) => setNewDeckName(e.target.value)}
										autoFocus
									/>
									<div className="mt-2">
										<Button
											type="submit"
											className="btn btn-primary user-decks-btn me-2"
										>
											Save
										</Button>
										<Button
											className="btn btn-warning user-decks-btn"
											onClick={() => setQuickCreate(false)}
										>
											Cancel
										</Button>
									</div>
								</form>
							) : (
								<div className="user-deck" onClick={() => setQuickCreate(true)}>
									<span className="m-0 default-text bold-text">Create New</span>
								</div>
							)}
							{userDecks
								? userDecks.map((userDeck) => (
										<div
											key={userDeck.id}
											className="user-deck"
											onClick={() => addDeckCard(userDeck.id)}
										>
											<span className="m-0 default-text">{userDeck.name}</span>
										</div>
								  ))
								: ""}
						</Modal.Body>
					</Modal>
				</>
			)}
		</>
	);
};

export default CardInfo;
