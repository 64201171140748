import { useEffect, useState, useContext } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { Helmet } from "react-helmet";

import Section from "../components/Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Deck from "../components/Deck";
import Loader from "../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSearch,
	faUser,
	faBook,
	faCalendar,
	faTableCellsLarge,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import PageHeaderBg from "../components/PageHeaderBg";
import Pagination from "../components/Pagination";

const Decks = () => {
	const [user] = useContext(UserContext);
	const navigate = useNavigate();
	try {
		var user_obj = JSON.parse(user);
	} catch {
		var user_obj = null;
	}

	const [loading, setLoading] = useState(true);
	const [searching, setSearching] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams({});
	const [decks, fetchDecks] = useState([]);
	const [meta, fetchMeta] = useState([]);

	const [name, setName] = useState(searchParams.get("q") || "");
	const [activeFormat, setActiveFormat] = useState("all");
	const [items, fetchItems] = useState([]);
	const [selectedItem, setSelectedItem] = useState(null);
	const [rawSelected, setRawSelected] = useState(true);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [loadingSuggestions, setLoadingSuggestions] = useState(false);
	const [fromSuggestions, setFromSuggestions] = useState(false);
	const [rawName, setRawName] = useState("");
	const [searchFocused, setSearchFocused] = useState(false);
	const [formatFilter, setFormatFilter] = useState(
		searchParams.get("format") ? searchParams.get("format") : ""
	);

	const getData = () => {
		fetch(`${process.env.REACT_APP_API_URI}/decks/${window.location.search}`)
			.then((res) => res.json())
			.then((res) => {
				fetchDecks(res.data);
				fetchMeta(res.meta);
				setLoading(false);
				setSearching(false);
			});
	};

	const nextPage = () => {
		setSearchParams({
			page: searchParams.get("page")
				? parseInt(searchParams.get("page")) + 1
				: 2,
			q: name,
			format: formatFilter,
		});
	};

	const previousPage = () => {
		setSearchParams({
			page: parseInt(searchParams.get("page")) - 1,
			q: name,
			format: formatFilter,
		});
	};

	const highlightSuggestion = (e) => {
		if (e.keyCode === 40) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem < items.length - 1) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem + 1);
				} else {
					if (rawSelected) {
						setSelectedItem(0);
						setRawSelected(false);
					} else {
						setSelectedItem(null);
						setRawSelected(true);
					}
				}
			} else {
				setRawSelected(!rawSelected);
			}
		} else if (e.keyCode === 38) {
			e.preventDefault();
			setFromSuggestions(true);
			if (items.length !== 0) {
				if (selectedItem !== null && selectedItem !== 0) {
					setSelectedItem((prevSelectedItem) => prevSelectedItem - 1);
				} else {
					if (selectedItem === 0) {
						setSelectedItem(null);
						setRawSelected(true);
					} else {
						setSelectedItem(items.length - 1);
						setRawSelected(false);
					}
				}
			} else {
				setRawSelected(!rawSelected);
			}
		} else {
			setFromSuggestions(false);
		}
	};

	const getSuggestions = () => {
		setLoadingSuggestions(true);
		fetch(
			`${process.env.REACT_APP_API_URI}/decks/suggestions?q=${name}&format=${activeFormat}`
		)
			.then((res) => res.json())
			.then((res) => {
				setSelectedItem(null);
				fetchItems(res.data);
				setLoadingSuggestions(false);
			});
	};

	const handleFilter = (e) => {
		e.preventDefault();
		if (selectedItem !== null) {
			navigate(`/decks/${items[selectedItem].id}`);
		} else {
			setSearchParams({
				page: 1,
				q: name,
				format: formatFilter,
			});
		}
		setShowSuggestions(false);
	};

	const handleName = (name) => {
		setName(name);
		setRawName(name);
	};

	const handleSuggestionHover = () => {
		setRawSelected(false);
		setFromSuggestions(true);
		setSelectedItem(null);
	};

	const handleSearchSelect = (e, item_name) => {
		e.preventDefault();
		setShowSuggestions(false);
		setRawSelected(true);
		if (item_name) {
			setName(item_name);
			setSearchParams({
				page: 1,
				q: item_name,
				format: formatFilter,
			});
		}
	};

	const handleFormat = (e, format) => {
		e.preventDefault();
		setFormatFilter(format);
		setSearchParams({
			page: 1,
			q: name,
			format: format,
		});
	};

	useEffect(() => {
		getData();
		setFormatFilter(
			searchParams.get("format") ? searchParams.get("format") : ""
		);
	}, [searchParams]);

	useEffect(() => {
		if (!fromSuggestions) {
			if (name !== "" && searchFocused) {
				setShowSuggestions(true);
				setLoadingSuggestions(true);
				const timer = setTimeout(() => {
					getSuggestions();
				}, 500);
				return () => clearTimeout(timer);
			} else {
				setShowSuggestions(false);
				setLoadingSuggestions(true);
			}
		}
	}, [name, activeFormat]);

	return (
		<>
			<Helmet>
				<title>Decks | Pokebuilder</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : (
				<Section className="full-page pb-5">
					<div className="page-header decks flex-row-left">
						<Container>
							<h1 className="page-header-title text-xl white-text bold-text upper mb-0">
								Decks
							</h1>
						</Container>
						<PageHeaderBg className="decks" />
					</div>
					<Container>
						<div className="deck-filters main flex-row-left">
							<div className="deck-filters-row col-md-10 col-12 flex-row-left">
								<div className="search-container">
									<Form onSubmit={handleFilter}>
										<input
											value={name}
											className="form-control search-text rounded"
											placeholder="Search decks"
											type="text"
											onChange={(e) => handleName(e.target.value)}
											onKeyDown={highlightSuggestion}
											onFocus={() => setSearchFocused(true)}
										/>
										<Button
											type="submit"
											className="search-input-icon flex-row-right"
										>
											<FontAwesomeIcon icon={faSearch} />
										</Button>
									</Form>
									<div
										className={`suggestions-container background-white ${
											showSuggestions ? "" : "hidden"
										}`}
									>
										{name && (
											<a
												href="#"
												className={`raw-search-result search-suggestion default-text ellipsis ${
													rawSelected && "selected"
												}`}
												onMouseOver={handleSuggestionHover}
												onClick={(e) => handleSearchSelect(e, rawName)}
											>
												<FontAwesomeIcon icon={faSearch} className="me-2" />
												Search for "{rawName}"
											</a>
										)}
										{!loadingSuggestions ? (
											items.map((item, index) => (
												<Link
													to={`/decks/${item.id}`}
													key={index}
													onMouseOver={handleSuggestionHover}
													className={`search-suggestion deck-suggestion default-text ellipsis ${
														selectedItem === index ? "selected" : ""
													}`}
												>
													<div className="flex-row-left">
														<div className="suggestion-img">
															<img src={item.img} alt="Deck card photo" />
														</div>
														<div className="suggestion-info">
															<span className="suggestion-name bold-text m-0">
																{item.name}
															</span>
															<div className="flex-row-left">
																<div className="suggestion-sub-info flex-row-left">
																	<FontAwesomeIcon
																		icon={faUser}
																		className="suggestion-icon"
																	/>
																	<span className="label-md">
																		{item.user.username}
																	</span>
																</div>
																<div className="suggestion-sub-info flex-row-left">
																	<FontAwesomeIcon
																		icon={faBook}
																		className="suggestion-icon"
																	/>
																	<span className="label-md">
																		{item.format}
																	</span>
																</div>
																<div className="suggestion-sub-info flex-row-left">
																	<FontAwesomeIcon
																		icon={faCalendar}
																		className="suggestion-icon calendar"
																	/>
																	<span className="label-md">
																		{new Date(
																			item.created_at
																		).toLocaleDateString()}
																	</span>
																</div>
															</div>
														</div>
													</div>
												</Link>
											))
										) : (
											<div className="suggestions-loader flex-column">
												<Spinner
													as="span"
													animation="border"
													size="sm"
													className="default-text"
												/>
											</div>
										)}
									</div>
								</div>
								<div className="format-dropdown-container md-format-dropdown flex-row-left">
									<FontAwesomeIcon
										icon={faTableCellsLarge}
										className="btn-icon white-text"
									/>
									<Form.Select
										className="format-dropdown background-default white-text align-left"
										onChange={(e) => handleFormat(e, e.target.value)}
										value={formatFilter}
									>
										<option value="">All Formats</option>
										<option value="standard">Standard</option>
										<option value="expanded">Expanded</option>
										<option value="theme">Theme</option>
										<option value="unlimited">Legacy</option>
									</Form.Select>
								</div>
								<div className="filter-btn-container flex-row-left">
									<a
										href="#"
										className={`format-picker white-text rounded opacity-5 ${
											formatFilter === "" && "active"
										}`}
										onClick={(e) => handleFormat(e, "")}
									>
										All Formats
									</a>
									<a
										href="#"
										className={`format-picker white-text rounded opacity-5 ${
											formatFilter === "standard" && "active"
										}`}
										onClick={(e) => handleFormat(e, "standard")}
									>
										Standard
									</a>
									<a
										href="#"
										className={`format-picker white-text rounded opacity-5 ${
											formatFilter === "expanded" && "active"
										}`}
										onClick={(e) => handleFormat(e, "expanded")}
									>
										Expanded
									</a>
									<a
										href="#"
										className={`format-picker white-text rounded opacity-5 ${
											formatFilter === "theme" && "active"
										}`}
										onClick={(e) => handleFormat(e, "theme")}
									>
										Theme
									</a>
									<a
										href="#"
										className={`format-picker white-text rounded opacity-5 ${
											formatFilter === "unlimited" && "active"
										}`}
										onClick={(e) => handleFormat(e, "unlimited")}
									>
										Legacy
									</a>
								</div>
							</div>
							<div className="col-md-2 col-12">
								<Row className="sm-format-container">
									<Col className="sm-format-dropdown">
										<div className="sm-format-dropdown-container flex-row-left">
											<FontAwesomeIcon
												icon={faTableCellsLarge}
												className="btn-icon white-text"
											/>
											<Form.Select
												className="format-dropdown background-default white-text align-left"
												onChange={(e) => handleFormat(e, e.target.value)}
												value={formatFilter}
											>
												<option value="">All Formats</option>
												<option value="standard">Standard</option>
												<option value="expanded">Expanded</option>
												<option value="theme">Theme</option>
												<option value="unlimited">Legacy</option>
											</Form.Select>
										</div>
									</Col>
								</Row>
							</div>
						</div>
						{searching ? (
							<Loader className="search-loader" />
						) : (
							<>
								<Row>
									{decks.map((deck) => (
										<Col
											className="col-lg-3 col-md-4 col-sm-6 col-12"
											key={deck.id}
										>
											<Deck
												deck={deck}
												user_id={user_obj ? user_obj.user_id : null}
											/>
										</Col>
									))}
								</Row>
								<Pagination
									meta={meta}
									nextPage={nextPage}
									previousPage={previousPage}
									className="flex-row-center mt-5 default-text"
								/>
							</>
						)}
					</Container>
				</Section>
			)}
			;
		</>
	);
};

export default Decks;
