import { React, useContext } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext";
import { NotifProvider, NotifContext } from "./context/NotifContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./fonts/Rajdhani-Light.ttf";
import "./fonts/Rajdhani-Regular.ttf";
import "./fonts/Rajdhani-Medium.ttf";
import "./fonts/Rajdhani-SemiBold.ttf";
import "./fonts/Rajdhani-Bold.ttf";
import "./index.css";
import "react-markdown-editor-lite/lib/index.css";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import NoPage from "./pages/NoPage";
import Cards from "./pages/Cards";
import Decks from "./pages/Decks";
import Deck from "./pages/Deck";
import Sets from "./pages/Sets";
import Set from "./pages/Set";
import CardInfo from "./pages/CardInfo";
import DeckBuilder from "./pages/DeckBuilder";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import ScrollToTop from "./components/ScrollToTop";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import ComingSoon from "./pages/ComingSoon";
import Settings from "./pages/Settings";

import SnackbarProvider from "react-simple-snackbar";
import { UserProvider } from "./context/UserContext";
import { ClipboardProvider } from "./context/ClipboardContext";
import { AccessProvider } from "./context/AccessContext";
import { ViewProvider } from "./context/ViewContext";
import Notifications from "./pages/Notifications";

export default function App() {
	const [user] = useContext(UserContext);
	try {
		var user_obj = JSON.parse(user);
	} catch {
		var user_obj = null;
	}
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/*" element={<Layout />}>
					<Route index element={<Home />} />
					<Route
						path="signup"
						element={user_obj ? <Navigate to="/" /> : <SignUp />}
					/>
					<Route
						path="login"
						element={user_obj ? <Navigate to="/" /> : <Login />}
					/>
					<Route path="password/forgot" element={<ForgotPassword />} />
					<Route path="password/reset/:token" element={<ResetPassword />} />
					<Route path="builder" element={<DeckBuilder />} />
					<Route path="decks" element={<Decks />} />
					<Route path="cards" element={<Cards />} />
					<Route path="sets" element={<Sets />} />
					<Route path="sets/:id" element={<Set />} />
					<Route path="decks/:id" element={<Deck />} />
					<Route path="cards/:id" element={<CardInfo />} />
					<Route path="notifications" element={<Notifications />} />
					<Route
						path="settings"
						element={user_obj ? <Settings /> : <Navigate to="/login" />}
					/>
					<Route path="about" element={<About />} />
					<Route path="contact" element={<Contact />} />
					<Route path="privacy" element={<Privacy />} />
					<Route path="future" element={<ComingSoon />} />
					<Route path="404" element={<NoPage />} />
					<Route path="*" element={<NoPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

ReactDOM.render(
	<UserProvider>
		<AccessProvider>
			<NotifProvider>
				<SnackbarProvider>
					<ClipboardProvider>
						<ViewProvider>
							<App />
						</ViewProvider>
					</ClipboardProvider>
				</SnackbarProvider>
			</NotifProvider>
		</AccessProvider>
	</UserProvider>,
	document.getElementById("root")
);
