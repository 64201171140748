import PokeSilhouette from "./PokeSilhouette";

const PageHeaderBg = ({ className }) => {
	return (
		<div className="page-header-bg flex-row-right">
			<PokeSilhouette className={`page-header-pokeball ${className}`} />
			<PokeSilhouette className={`page-header-pokeball ${className}`} />
			<PokeSilhouette className={`page-header-pokeball ${className}`} />
			<PokeSilhouette className={`page-header-pokeball ${className}`} />
			<PokeSilhouette className={`page-header-pokeball ${className}`} />
		</div>
	);
};

export default PageHeaderBg;
