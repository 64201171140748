import { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loader from "../components/Loader";

const Notifications = () => {
	const [user] = useContext(UserContext);
	const [notifications, setNotifications] = useState([]);
	const [loading, setLoading] = useState(true);

	try {
		var user_obj = JSON.parse(user);
	} catch {
		var user_obj = null;
	}

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user_obj.auth_token,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/users/notifications`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setNotifications(data.notifications);
		}
		setLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			<Helmet>
				<title>Notifications | Pokebuilder</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : (
				<div className="full-page pt-5 pb-5 background-light">
					<Container>
						<Row>
							<Col className="col-lg-8 col-12">
								{notifications.map((notif) => (
									<a href={notif.link}>
										<div className="rounded background-white p-20 mb-20">
											<span className="text-md bold-text default-text">
												{notif.title}
											</span>
											<p className="text-md dark-text mb-0">{notif.body}</p>
										</div>
									</a>
								))}
							</Col>
						</Row>
					</Container>
				</div>
			)}
		</>
	);
};

export default Notifications;
