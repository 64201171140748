import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../context/UserContext";
import { ClipboardContext } from "../context/ClipboardContext";
import { AccessContext } from "../context/AccessContext";
import { ViewContext } from "../context/ViewContext";
import { Helmet } from "react-helmet";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardPlaceholder from "../components/CardPlaceholder";
import {
	faHeart,
	faComment,
	faEye,
	faPen,
	faList,
	faGrip,
	faShare,
	faTriangleExclamation,
	faCodeCompare,
	faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

import DeckCard from "../components/DeckCard";
import AddCardsForm from "../components/AddCardsForm";
import Loader from "../components/Loader";
import { useSnackbar } from "react-simple-snackbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import MarkdownIt from "markdown-it";
import Spinner from "react-bootstrap/Spinner";

import Comments from "../components/Comments";

const Deck = () => {
	let { id } = useParams();
	const commentSectionRef = useRef(null);
	const replyRef = useRef(null);
	const [, setClipboard] = useContext(ClipboardContext);
	const [accessKey] = useContext(AccessContext);
	const [view, setView] = useContext(ViewContext);
	const [loading, setLoading] = useState(true);
	const [warnings, setWarnings] = useState([]);
	const [sectionToEdit, setSectionToEdit] = useState(null);
	const [user] = useContext(UserContext);
	const [preview, setPreview] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);
	const [cardsToCopy, setCards] = useState([]);
	const [deckView, setDeckView] = useState(null);
	const [title, setTitle] = useState("");
	const [descInput, setDescInput] = useState("");
	const [desc, setDesc] = useState("");
	const [deck, fetchDeck] = useState([]);
	const [views, fetchViews] = useState([]);
	const [deckComments, fetchDeckComments] = useState([]);
	const [sections, fetchSections] = useState([]);
	const [pokemonCards, setPokemonCards] = useState([]);
	const [energyCards, setEnergyCards] = useState([]);
	const [trainerCards, setTrainerCards] = useState([]);
	const [comparePokemonCards, setComparePokemonCards] = useState([]);
	const [compareEnergyCards, setCompareEnergyCards] = useState([]);
	const [compareTrainerCards, setCompareTrainerCards] = useState([]);
	const [comment, setComment] = useState("");
	const [commentLoading, setCommentLoading] = useState(false);
	const [tags, setTags] = useState([]);
	const [renameDeck, setRenameDeck] = useState(false);
	const [renameColumn, setRenameColumn] = useState(null);
	const [columnName, setColumnName] = useState(null);
	const [addCardsModal, setAddCardsModal] = useState(false);
	const [descriptionModal, setDescriptionModal] = useState(false);
	const [sectionModal, setSectionModal] = useState(false);
	const [viewModal, setViewModal] = useState(false);
	const [compareModal, setCompareModal] = useState(false);
	const [deleteDeckModal, setDeleteDeckModal] = useState(false);
	const [deleteSectionModal, setDeleteSectionModal] = useState(false);
	const [editDesc, setEditDesc] = useState(false);
	const [editName, setEditName] = useState(false);
	const [mentions, setMentions] = useState([]);
	const [replyMentions, setReplyMentions] = useState([]);
	const [usernames, setUsernames] = useState([]);
	const [parentId, setParentId] = useState(null);
	const [replyLoading, setReplyLoading] = useState(false);
	const [replyContent, setReplyContent] = useState("");
	const [commentPlain, setCommentPlain] = useState("");
	const [replyContentPlain, setReplyContentPlain] = useState("");
	const [compareLoading, setCompareLoading] = useState(false);
	const [userDecks, setUserDecks] = useState([]);
	const [compareDeck, setCompareDeck] = useState(null);

	const [likes, setLikes] = useState(0);
	const [liked, setLiked] = useState(false);

	const navigate = useNavigate();
	const mdParser = new MarkdownIt();

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [defaultSnackbar] = useSnackbar();

	const KeyCodes = {
		comma: 188,
		enter: 13,
	};

	const delimiters = [KeyCodes.comma, KeyCodes.enter];

	try {
		var auth_token = JSON.parse(user).auth_token;
		var user_id = JSON.parse(user).user_id;
	} catch {
		var auth_token = null;
		var user_id = null;
	}

	const getData = () => {
		fetch(`${process.env.REACT_APP_API_URI}/decks/info/${id}`)
			.then((res) => res.json())
			.then((res) => {
				fetchDeck(res.deck);
				fetchDeckComments(res.comments);
				setTitle(res.deck.name);
				setDesc(res.deck.desc);
				setDescInput(res.deck.desc);
				fetchSections(res.sections);
				fetchViews(res.views);
				setTags(res.tags);
				setPokemonCards(
					res.deck.deck_cards
						.filter((deck_card) => deck_card.card.supertype == "Pokémon")
						.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
				);
				setEnergyCards(
					res.deck.deck_cards
						.filter((deck_card) => deck_card.card.supertype == "Energy")
						.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
				);
				setTrainerCards(
					res.deck.deck_cards
						.filter((deck_card) => deck_card.card.supertype == "Trainer")
						.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
				);
				setLikes(res.deck.likes.length);
				setLiked(
					res.deck.likes.filter((existing) => existing.user_id == user_id)
						.length != 0
				);
				setLoading(false);
			});
	};

	const getTotal = () => {
		var total = 0;
		for (let deck_card of deck.deck_cards) {
			if (deck_card.card.market_price) {
				total += deck_card.card.market_price;
			}
		}
		setTotalPrice(Math.round((total + Number.EPSILON) * 100) / 100);
	};

	const toggleLike = async (e) => {
		e.preventDefault();

		if (liked) {
			setLikes(likes - 1);
		} else {
			setLikes(likes + 1);
		}
		setLiked(!liked);

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				user_id: user_id,
				deck_id: deck.id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/like/toggle`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		}
	};

	const engageComment = async (url, commentID) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				comment_id: commentID,
				user_id: user_id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/comments/${url}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			fetchDeckComments(data.comments);
		}
	};

	const copyDeck = () => {
		var newClipboard = [];
		for (let deck_card of deck.deck_cards) {
			if (
				newClipboard.filter((existing) => existing.id == deck_card.card.id)
					.length != 0
			) {
				// const newClipboard = [...clipboard];
				newClipboard.filter(
					(existing) => existing.id == deck_card.card.id
				)[0].qty += 1;
				setClipboard(newClipboard);
			} else {
				newClipboard = [
					...newClipboard,
					{
						id: deck_card.card.id,
						tcg_id: deck_card.card.tcg_id,
						number: deck_card.card.number,
						ptcgo_code:
							(deck_card.card.card_set && deck_card.card.card_set.ptcgo_code) ||
							"",
						supertype: deck_card.card.supertype,
						img: deck_card.card.lg_img,
						name: deck_card.card.name,
						qty: 1,
					},
				];
				setClipboard(newClipboard);
			}
		}

		// for (let deck_card of deck.deck_cards) {
		// 	if (
		// 		newClipboard.filter((existing) => existing.id == deck_card.card.id)
		// 			.length != 0
		// 	) {
		// 		// const newClipboard = [...clipboard];
		// 		newClipboard.filter(
		// 			(existing) => existing.id == deck_card.card.id
		// 		)[0].qty += 1;
		// 		setClipboard(newClipboard);
		// 	} else {
		// 		newClipboard = [
		// 			...newClipboard,
		// 			{
		// 				id: deck_card.card.id,
		// 				tcg_id: deck_card.card.tcg_id,
		// 				number: deck_card.card.number,
		// 				ptcgo_code: deck_card.card.card_set.ptcgo_code,
		// 				supertype: deck_card.card.supertype,
		// 				img: deck_card.card.lg_img,
		// 				name: deck_card.card.name,
		// 				qty: 1,
		// 			},
		// 		];
		// 		setClipboard(newClipboard);
		// 	}
		// }
		defaultSnackbar("Added to clipboard.");
	};

	const handleDelete = async (i) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				deck_tag_id: tags[i].deck_tag_id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/delete_tag`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setTags(tags.filter((tag, index) => index !== i));
		}
	};

	const handleAddition = async (tag) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				deck_id: id,
				tag: tag.text,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/add_tag`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setTags([...tags, data]);
		}
	};

	const handleDrag = (tag, currPos, newPos) => {
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		// re-render
		setTags(newTags);
	};

	const deleteDeck = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				deck_id: id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/delete`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setDeleteDeckModal(false);
			navigate("/builder");
			successSnackbar(data.message);
		}
	};

	const deleteSection = async (section) => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				deck_id: deck.id,
				section_id: section.id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/sections/delete`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			fetchSections(data.sections);
			successSnackbar("Section deleted.");
		}
		setDeleteSectionModal(false);
	};

	const editSection = (section, modal) => {
		setSectionToEdit(section);
		modal(true);
	};

	const createNewSection = () => {
		setSectionToEdit(null);
		setSectionModal(true);
	};

	const submitTitleForm = (e) => {
		e.preventDefault();
		document.getElementById("titleText").blur();
	};

	const submitColumnForm = (e) => {
		e.preventDefault();
		document.getElementById("columnName").blur();
	};

	const saveDeckTitle = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				deck_id: id,
				name: title,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/edit`,
			requestOptions
		);

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		}

		setRenameDeck(false);
	};

	const saveDesc = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				deck_id: id,
				desc: descInput,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/edit`,
			requestOptions
		);

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			setDesc(descInput);
			setEditDesc(false);
		}
	};

	const getUserDecks = () => {
		setCompareLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
				"X-Access-Key": accessKey,
			},
		};

		fetch(
			`${process.env.REACT_APP_API_URI}/decks/my_decks${window.location.search}`,
			requestOptions
		)
			.then((res) => res.json())
			.then((res) => {
				setUserDecks(res.data);
				setCompareLoading(false);
			});
	};

	const getUserDeckCards = (compareDeck) => {
		setCompareLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
				"X-Access-Key": accessKey,
			},
		};

		fetch(
			`${process.env.REACT_APP_API_URI}/decks/info/${compareDeck.id}`,
			requestOptions
		)
			.then((res) => res.json())
			.then((res) => {
				setComparePokemonCards(
					res.deck.deck_cards
						.filter((deck_card) => deck_card.card.supertype == "Pokémon")
						.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
				);
				setCompareEnergyCards(
					res.deck.deck_cards
						.filter((deck_card) => deck_card.card.supertype == "Energy")
						.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
				);
				setCompareTrainerCards(
					res.deck.deck_cards
						.filter((deck_card) => deck_card.card.supertype == "Trainer")
						.sort((a, b) => (a.card.id > b.card.id ? 1 : -1))
				);
				setCompareLoading(false);
			});
	};

	const editColumnName = (column) => {
		setRenameColumn(column.id);
		setColumnName(column.name);
	};

	const handleCommentLinkClick = (e) => {
		e.preventDefault();
		commentSectionRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const handleDesc = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();
			saveDesc();
		}
	};

	const handleShare = () => {
		if (navigator.share) {
			navigator
				.share({
					title: deck.name,
					text: deck.desc,
					url: "https://pokebuilder.net/decks/" + deck.id,
				})
				.then(() => console.log("Shared successfully"))
				.catch((error) => console.log("Error sharing:", error));
		} else {
			navigator.clipboard.writeText("https://pokebuilder.net/decks/" + deck.id);
			defaultSnackbar("Link copied.");
		}
	};

	const shortenNumber = (n) => {
		if (n < 1e3) return n;
		if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
		if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
		if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
		if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
	};

	const openCompareModal = () => {
		getUserDecks();
		setCompareModal(true);
	};

	const closeCompareModal = () => {
		setCompareModal(false);
		setCompareDeck(null);
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		setIsLoaded(false);
	}, [preview]);

	useEffect(() => {
		if (deck && deck.deck_cards) {
			getTotal();
			var newWarnings = [];
			if (deck.deck_cards.length !== 60) {
				newWarnings.push(
					`Decks must contain exactly 60 cards. (${deck.deck_cards.length}/60)`
				);
			}
			if (
				deck.deck_cards.filter(
					(deckCard) =>
						deckCard.card.supertype === "Pokémon" &&
						deckCard.card.subtypes.some(
							(subtype) => subtype.subtype.name === "Basic"
						)
				).length === 0
			) {
				newWarnings.push(`Decks must have at least 1 BASIC Pokémon card.`);
			}
			const cardCounts = deck.deck_cards.reduce((acc, deckCard) => {
				const card = deckCard.card;
				const cardName = card.name;
				const isBasicEnergy = card.subtypes.some(
					(subtype) => subtype.subtype.name === "Basic Energy"
				);
				if (!isBasicEnergy && card.subtypes.length !== 0) {
					acc[cardName] = (acc[cardName] || 0) + 1;
				}
				return acc;
			}, {});

			Object.entries(cardCounts)
				.filter(([cardName, count]) => count > 4)
				.map(([cardName, count]) => {
					newWarnings.push(`${cardName} has more then 4 copies.`);
				});
			setWarnings(newWarnings);
		}
	}, [deck]);

	const addComment = async (e) => {
		e.preventDefault();
		setCommentLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				comment: commentPlain,
				deck_id: deck.id,
				mentions: mentions,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/comments/add`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong");
		} else {
			fetchDeckComments(data.comments);
			setComment("");
			setCommentPlain("");
			setMentions([]);
			successSnackbar("Comment posted.");
		}
		setCommentLoading(false);
	};

	const addReply = async (e) => {
		e.preventDefault();
		setReplyLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": auth_token,
			},
			body: JSON.stringify({
				comment: replyContentPlain,
				deck_id: deck.id,
				parent_id: parentId,
				mentions: replyMentions,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_API_URI}/decks/comments/add`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong");
		} else {
			fetchDeckComments(data.comments);
			setReplyContent("");
			setReplyContentPlain("");
			setReplyMentions([]);
			successSnackbar("Reply posted.");
		}
		setReplyLoading(false);
	};

	// OTHER SCREENS

	return (
		<>
			<Helmet>
				<title>{deck?.name || "Decks"} | Pokebuilder</title>
			</Helmet>
			{loading ? (
				<Loader />
			) : (
				<>
					<div className="content full-page">
						<Container>
							<Row>
								<Col className="deck-details-sm col-lg-3 col-12">
									<div className="deck-details full rounded">
										<div className="deck-details-header background-default">
											<h1 className="text-xl text-white bold-text m-0">
												{deck.name}
											</h1>
										</div>
										<div className="deck-details-body background-light">
											<Row className="deck-details-row">
												<Col className="border-bottom">
													<span className="default-text bold-text">Format</span>
												</Col>
												<Col className="border-bottom align-right">
													<span className="default-text">{deck.format}</span>
												</Col>
											</Row>
											<Row className="deck-details-row">
												<Col className="border-bottom">
													<span className="default-text bold-text">
														Total Cost
													</span>
												</Col>
												<Col className="border-bottom align-right">
													<span className="default-text">{totalPrice}</span>
												</Col>
											</Row>
											<Row className="deck-details-row">
												<Col className="border-bottom">
													<span className="default-text bold-text">
														Uploaded
													</span>
												</Col>
												<Col className="border-bottom align-right">
													<span className="default-text">
														{new Date(deck.created_at).toLocaleDateString()}
													</span>
												</Col>
											</Row>
											<Row className="deck-details-row">
												<Col className="border-bottom">
													<span className="default-text bold-text">
														Created by
													</span>
												</Col>
												<Col className="border-bottom align-right">
													<span className="default-text">
														{deck.user?.username}
													</span>
												</Col>
											</Row>
											<Row className="deck-details-row">
												<Col>
													<div className="flex-row-left">
														<span className="default-text bold-text">
															Description
														</span>
														{(deck.user && user_id == deck.user.id) ||
														deck.access_key == accessKey ? (
															<div className="flex-row-right full">
																<a href="#" onClick={() => setEditDesc(true)}>
																	<FontAwesomeIcon
																		icon={faPen}
																		className="edit-icon opacity-5 default-text text-md"
																	/>
																</a>
															</div>
														) : (
															""
														)}
													</div>
													{editDesc ? (
														<Form.Control
															autoFocus
															as="textarea"
															className="desc-input"
															value={descInput}
															onChange={(e) => setDescInput(e.target.value)}
															onKeyDown={handleDesc}
															onBlur={saveDesc}
														/>
													) : (
														<p className="default-text m-0">{desc}</p>
													)}
												</Col>
											</Row>
											{(deck.user && user_id == deck.user.id) ||
											deck.access_key == accessKey ? (
												<Row className="deck-details-row">
													<Col>
														<Button
															variant="danger"
															className="delete-deck-btn upper full medium-text text-md rounded"
															onClick={() => setDeleteDeckModal(true)}
														>
															Delete Deck
														</Button>
													</Col>
												</Row>
											) : (
												""
											)}
										</div>
										<div className="deck-details-footer flex-row-right">
											{user_id ? (
												<a
													href="#"
													className={`deck-preview-engagement-icon likes ${
														liked && "active"
													}`}
													onClick={toggleLike}
												>
													<FontAwesomeIcon icon={faHeart} />
												</a>
											) : (
												<Link
													to="/login"
													className={`deck-preview-engagement-icon likes ${
														liked && "active"
													}`}
												>
													<FontAwesomeIcon icon={faHeart} />
												</Link>
											)}
											<span className="text-sm default-text">
												{shortenNumber(likes)}
											</span>
											<a
												href="#"
												className="deck-preview-engagement-icon comments"
												onClick={handleCommentLinkClick}
											>
												<FontAwesomeIcon icon={faComment} />
											</a>
											<span className="text-sm default-text">
												{shortenNumber(deck.deck_comments.length)}
											</span>
											<a
												href="#"
												className="deck-preview-engagement-icon views"
											>
												<FontAwesomeIcon icon={faEye} />
											</a>
											<span className="text-sm default-text">
												{shortenNumber(deck.view_count)}
											</span>
										</div>
									</div>
									{deck.user && user_id !== deck.user.id ? (
										<Button
											className="btn-gray flex-row-center share-btn full rounded mb-10"
											onClick={openCompareModal}
										>
											<FontAwesomeIcon
												icon={faCodeCompare}
												className="btn-icon"
											/>
											Compare
										</Button>
									) : (
										""
									)}
									<Button
										className="btn-gray flex-row-center share-btn full rounded"
										onClick={handleShare}
									>
										<FontAwesomeIcon icon={faShare} className="btn-icon" />
										Share
									</Button>
								</Col>
								<Col className="col-lg-9 col-12">
									<div
										className={`page-content-header deck-content-header flex-row-left background-default rounded ${
											(deck.user && user_id == deck.user.id) ||
											deck.access_key == accessKey
												? ""
												: "keep-row"
										}`}
									>
										<div
											className={
												(deck.user && user_id == deck.user.id) ||
												deck.access_key == accessKey
													? "col-md-8 col-12 deck-content-btn-container"
													: "col-6"
											}
										>
											<Button
												className="deck-header-btn btn-yellow upper bold-text rounded"
												onClick={copyDeck}
											>
												Add to Clipboard
											</Button>
											{(deck.user && user_id == deck.user.id) ||
											deck.access_key == accessKey ? (
												<Button
													className="deck-header-btn btn-purple upper bold-text rounded"
													onClick={() => setAddCardsModal(true)}
												>
													Add Cards
												</Button>
											) : (
												""
											)}
										</div>
										<div
											className={
												(deck.user && user_id == deck.user.id) ||
												deck.access_key == accessKey
													? "col-md-4 col-12 flex-row-right deck-view-picker-container"
													: "col-6 flex-row-right deck-view-picker-container"
											}
										>
											<a
												href="#"
												className={`deck-view-picker white-text rounded flex-column ${
													view === "list" && "active"
												}`}
												onClick={() => setView("list")}
											>
												<FontAwesomeIcon icon={faList} />
											</a>
											<a
												href="#"
												className={`deck-view-picker white-text rounded flex-column ${
													view === "grid" && "active"
												}`}
												onClick={() => setView("grid")}
											>
												<FontAwesomeIcon icon={faGrip} />
											</a>
										</div>
									</div>
									<div className="deck-warnings-container roounded">
										{warnings?.map((warning) => (
											<div
												key={warning}
												className="deck-warning flex-row-left full"
											>
												<FontAwesomeIcon
													icon={faTriangleExclamation}
													className="warning-icon"
												/>
												<span className="medium-text">{warning}</span>
											</div>
										))}
									</div>
									{view === "grid" ? (
										<Row>
											{pokemonCards.map(
												(deck_card, index) =>
													pokemonCards[index + 1]?.card.id !==
														deck_card.card.id && (
														<Col key={deck_card.id} className="col-md-3 col-6">
															<DeckCard
																id={deck_card.id}
																view={view}
																qty={
																	deck.deck_cards.filter(
																		(existing) =>
																			existing.card_id == deck_card.card_id
																	).length
																}
																className="grid"
																card={deck_card.card}
																setCards={setCards}
																ownedByUser={
																	(deck.user && user_id == deck.user.id) ||
																	deck.access_key == accessKey
																}
																authToken={auth_token}
																deckID={deck.id}
																fetchDeck={fetchDeck}
																setDeckView={setDeckView}
																setPokemonCards={setPokemonCards}
																setEnergyCards={setEnergyCards}
																setTrainerCards={setTrainerCards}
																setPreview={setPreview}
															/>
														</Col>
													)
											)}
											{energyCards.map(
												(deck_card, index) =>
													energyCards[index + 1]?.card.id !==
														deck_card.card.id && (
														<Col key={deck_card.id} className="col-md-3 col-6">
															<DeckCard
																id={deck_card.id}
																view={view}
																qty={
																	deck.deck_cards.filter(
																		(existing) =>
																			existing.card_id == deck_card.card_id
																	).length
																}
																className="grid"
																card={deck_card.card}
																setCards={setCards}
																ownedByUser={
																	(deck.user && user_id == deck.user.id) ||
																	deck.access_key == accessKey
																}
																authToken={auth_token}
																deckID={deck.id}
																fetchDeck={fetchDeck}
																setDeckView={setDeckView}
																setPokemonCards={setPokemonCards}
																setEnergyCards={setEnergyCards}
																setTrainerCards={setTrainerCards}
																setPreview={setPreview}
															/>
														</Col>
													)
											)}
											{trainerCards.map(
												(deck_card, index) =>
													trainerCards[index + 1]?.card.id !==
														deck_card.card.id && (
														<Col key={deck_card.id} className="col-md-3 col-6">
															<DeckCard
																id={deck_card.id}
																view={view}
																qty={
																	deck.deck_cards.filter(
																		(existing) =>
																			existing.card_id == deck_card.card_id
																	).length
																}
																className="grid"
																card={deck_card.card}
																setCards={setCards}
																ownedByUser={
																	(deck.user && user_id == deck.user.id) ||
																	deck.access_key == accessKey
																}
																authToken={auth_token}
																deckID={deck.id}
																fetchDeck={fetchDeck}
																setDeckView={setDeckView}
																setPokemonCards={setPokemonCards}
																setEnergyCards={setEnergyCards}
																setTrainerCards={setTrainerCards}
																setPreview={setPreview}
															/>
														</Col>
													)
											)}
										</Row>
									) : (
										<Row>
											<Col className="col-xl-4 col-12">
												<div className="clipboard-section deck-section rounded">
													<div className="clipboard-section-header flex-row-left background-default">
														<h3 className="title-sm white-text bold-text m-0">
															Pokémon
														</h3>
													</div>
													<div className="clipboard-section-body card-section-body background-light">
														{pokemonCards.map(
															(deck_card, index) =>
																pokemonCards[index + 1]?.card.id !==
																	deck_card.card.id && (
																	<DeckCard
																		key={deck_card.id}
																		id={deck_card.id}
																		view={view}
																		qty={
																			deck.deck_cards.filter(
																				(existing) =>
																					existing.card_id == deck_card.card_id
																			).length
																		}
																		className="grid"
																		card={deck_card.card}
																		setCards={setCards}
																		ownedByUser={
																			(deck.user && user_id == deck.user.id) ||
																			deck.access_key == accessKey
																		}
																		authToken={auth_token}
																		deckID={deck.id}
																		fetchDeck={fetchDeck}
																		setDeckView={setDeckView}
																		setPokemonCards={setPokemonCards}
																		setEnergyCards={setEnergyCards}
																		setTrainerCards={setTrainerCards}
																		setPreview={setPreview}
																	/>
																)
														)}
													</div>
												</div>
												<div className="clipboard-section deck-section rounded">
													<div className="clipboard-section-header flex-row-left background-default">
														<h3 className="title-sm white-text bold-text m-0">
															Energy
														</h3>
													</div>
													<div className="clipboard-section-body card-section-body background-light">
														{energyCards.map(
															(deck_card, index) =>
																energyCards[index + 1]?.card.id !==
																	deck_card.card.id && (
																	<DeckCard
																		key={deck_card.id}
																		id={deck_card.id}
																		view={view}
																		qty={
																			deck.deck_cards.filter(
																				(existing) =>
																					existing.card_id == deck_card.card_id
																			).length
																		}
																		className="grid"
																		card={deck_card.card}
																		setCards={setCards}
																		ownedByUser={
																			(deck.user && user_id == deck.user.id) ||
																			deck.access_key == accessKey
																		}
																		authToken={auth_token}
																		deckID={deck.id}
																		fetchDeck={fetchDeck}
																		setDeckView={setDeckView}
																		setPokemonCards={setPokemonCards}
																		setEnergyCards={setEnergyCards}
																		setTrainerCards={setTrainerCards}
																		setPreview={setPreview}
																	/>
																)
														)}
													</div>
												</div>
											</Col>
											<Col className="col-xl-4 col-12">
												<div className="clipboard-section deck-section rounded">
													<div className="clipboard-section-header flex-row-left background-default">
														<h3 className="title-sm white-text bold-text m-0">
															Trainer
														</h3>
													</div>
													<div className="clipboard-section-body card-section-body background-light">
														{trainerCards.map(
															(deck_card, index) =>
																trainerCards[index + 1]?.card.id !==
																	deck_card.card.id && (
																	<DeckCard
																		key={deck_card.id}
																		id={deck_card.id}
																		view={view}
																		qty={
																			deck.deck_cards.filter(
																				(existing) =>
																					existing.card_id == deck_card.card_id
																			).length
																		}
																		className="grid"
																		card={deck_card.card}
																		setCards={setCards}
																		ownedByUser={
																			(deck.user && user_id == deck.user.id) ||
																			deck.access_key == accessKey
																		}
																		authToken={auth_token}
																		deckID={deck.id}
																		fetchDeck={fetchDeck}
																		setDeckView={setDeckView}
																		setPokemonCards={setPokemonCards}
																		setEnergyCards={setEnergyCards}
																		setTrainerCards={setTrainerCards}
																		setPreview={setPreview}
																	/>
																)
														)}
													</div>
												</div>
											</Col>
											<Col className="col-xl-4 col-12">
												<div className="deck-card-preview background-light rounded">
													{preview ? (
														<>
															<div className="card-info-img">
																{!isLoaded ? (
																	<CardPlaceholder className="deck-card-placeholder" />
																) : (
																	""
																)}
																<img
																	src={preview.lg_img}
																	alt="Card preview"
																	className={`full ${
																		!isLoaded ? "hidden" : ""
																	}`}
																	onLoad={() => setIsLoaded(true)}
																/>
															</div>
															<div className="card-info-prices background-gray">
																<Row className="card-info-price">
																	<Col className="border-bottom">
																		<span className="default-text bold-text">
																			Low
																		</span>
																	</Col>
																	<Col className="align-right border-bottom">
																		<span className="default-text">
																			${preview.low_price}
																		</span>
																	</Col>
																</Row>
																<Row className="card-info-price">
																	<Col className="border-bottom">
																		<span className="default-text bold-text">
																			Mid
																		</span>
																	</Col>
																	<Col className="align-right border-bottom">
																		<span className="default-text">
																			${preview.mid_price}
																		</span>
																	</Col>
																</Row>
																<Row className="card-info-price">
																	<Col className="border-bottom">
																		<span className="default-text bold-text">
																			High
																		</span>
																	</Col>
																	<Col className="align-right border-bottom">
																		<span className="default-text">
																			${preview.high_price}
																		</span>
																	</Col>
																</Row>
																<Row className="card-info-price">
																	<Col>
																		<span className="default-text bold-text">
																			Market
																		</span>
																	</Col>
																	<Col className="align-right">
																		<span className="default-text">
																			${preview.market_price}
																		</span>
																	</Col>
																</Row>
															</div>
														</>
													) : (
														<span className="preview-empty flex-column default-text medium-text">
															Hover on a card to display here.
														</span>
													)}
												</div>
											</Col>
										</Row>
									)}
								</Col>
								<Col className="deck-details-lg col-lg-3 col-12">
									<div className="deck-details full rounded">
										<div className="deck-details-header background-default">
											<h1 className="text-xl text-white bold-text m-0">
												{deck.name}
											</h1>
										</div>
										<div className="deck-details-body background-light">
											<Row className="deck-details-row">
												<Col className="border-bottom">
													<span className="default-text bold-text">Format</span>
												</Col>
												<Col className="border-bottom align-right">
													<span className="default-text">{deck.format}</span>
												</Col>
											</Row>
											<Row className="deck-details-row">
												<Col className="border-bottom">
													<span className="default-text bold-text">
														Total Cost
													</span>
												</Col>
												<Col className="border-bottom align-right">
													<span className="default-text">{totalPrice}</span>
												</Col>
											</Row>
											<Row className="deck-details-row">
												<Col className="border-bottom">
													<span className="default-text bold-text">
														Uploaded
													</span>
												</Col>
												<Col className="border-bottom align-right">
													<span className="default-text">
														{new Date(deck.created_at).toLocaleDateString()}
													</span>
												</Col>
											</Row>
											<Row className="deck-details-row">
												<Col className="border-bottom">
													<span className="default-text bold-text">
														Created by
													</span>
												</Col>
												<Col className="border-bottom align-right">
													<span className="default-text">
														{deck.user?.username}
													</span>
												</Col>
											</Row>
											<Row className="deck-details-row">
												<Col>
													<div className="flex-row-left">
														<span className="default-text bold-text">
															Description
														</span>
														{(deck.user && user_id == deck.user.id) ||
														deck.access_key == accessKey ? (
															<div className="flex-row-right full">
																<a href="#" onClick={() => setEditDesc(true)}>
																	<FontAwesomeIcon
																		icon={faPen}
																		className="edit-icon opacity-5 default-text text-md"
																	/>
																</a>
															</div>
														) : (
															""
														)}
													</div>
													{editDesc ? (
														<Form.Control
															autoFocus
															as="textarea"
															className="desc-input"
															value={descInput}
															onChange={(e) => setDescInput(e.target.value)}
															onKeyDown={handleDesc}
															onBlur={saveDesc}
														/>
													) : (
														<p className="default-text m-0">{desc}</p>
													)}
												</Col>
											</Row>
											{(deck.user && user_id == deck.user.id) ||
											deck.access_key == accessKey ? (
												<Row className="deck-details-row">
													<Col>
														<Button
															variant="danger"
															className="delete-deck-btn upper full medium-text text-md rounded"
															onClick={() => setDeleteDeckModal(true)}
														>
															Delete Deck
														</Button>
													</Col>
												</Row>
											) : (
												""
											)}
										</div>
										<div className="deck-details-footer flex-row-right">
											{user_id ? (
												<a
													href="#"
													className={`deck-preview-engagement-icon likes ${
														liked && "active"
													}`}
													onClick={toggleLike}
												>
													<FontAwesomeIcon icon={faHeart} />
												</a>
											) : (
												<Link
													to="/login"
													className={`deck-preview-engagement-icon likes ${
														liked && "active"
													}`}
												>
													<FontAwesomeIcon icon={faHeart} />
												</Link>
											)}
											<span className="text-sm default-text">
												{shortenNumber(likes)}
											</span>
											<a
												href="#"
												className="deck-preview-engagement-icon comments"
												onClick={handleCommentLinkClick}
											>
												<FontAwesomeIcon icon={faComment} />
											</a>
											<span className="text-sm default-text">
												{shortenNumber(deck.deck_comments.length)}
											</span>
											<a
												href="#"
												className="deck-preview-engagement-icon views"
											>
												<FontAwesomeIcon icon={faEye} />
											</a>
											<span className="text-sm default-text">
												{shortenNumber(deck.view_count)}
											</span>
										</div>
									</div>
									{deck.user && user_id !== deck.user.id ? (
										<Button
											className="btn-gray flex-row-center share-btn full rounded mb-10"
											onClick={openCompareModal}
										>
											<FontAwesomeIcon
												icon={faCodeCompare}
												className="btn-icon"
											/>
											Compare
										</Button>
									) : (
										""
									)}
									<Button
										className="btn-gray flex-row-center share-btn full rounded"
										onClick={handleShare}
									>
										<FontAwesomeIcon icon={faShare} className="btn-icon" />
										Share
									</Button>
								</Col>
							</Row>
						</Container>
						<AddCardsForm
							id={id}
							fetchDeck={fetchDeck}
							setDeckView={setDeckView}
							setPokemonCards={setPokemonCards}
							setEnergyCards={setEnergyCards}
							setTrainerCards={setTrainerCards}
							isVisible={addCardsModal}
							setAddCardsModal={setAddCardsModal}
						/>
						<Modal
							show={deleteDeckModal}
							onHide={() => setDeleteDeckModal(false)}
							centered
						>
							<Modal.Header>
								<Modal.Title className="default-text bold-text">
									Delete Deck
								</Modal.Title>
							</Modal.Header>
							<Modal.Body className="pt-4 pb-4">
								<p className="m-0">
									Are you sure you want to delete this deck? This action cannot
									be undone.
								</p>
							</Modal.Body>
							<Modal.Footer>
								<Button
									type="button"
									className="btn btn-yellow rounded upper medium-text"
									onClick={() => setDeleteDeckModal(false)}
								>
									Cancel
								</Button>
								<Button
									type="button"
									variant="danger"
									className="upper medium-text text-md rounded"
									onClick={deleteDeck}
								>
									Delete
								</Button>
							</Modal.Footer>
						</Modal>
						<Modal
							show={compareModal}
							onHide={closeCompareModal}
							size="lg"
							centered
						>
							<Modal.Header>
								<Modal.Title className="default-text bold-text">
									Deck Comparison
								</Modal.Title>
							</Modal.Header>
							<Modal.Body className="pt-4 pb-4">
								<Row>
									<Col className="col-lg-6 col-12">
										<div className="deck-compare-side full border rounded p-10">
											<span className="text-lg bold-text default-text">
												{deck?.name}
											</span>
											<div className="p-30">
												{pokemonCards.map(
													(deck_card, index) =>
														pokemonCards[index + 1]?.card.id !==
															deck_card.card.id && (
															<DeckCard
																id={deck_card.id}
																view={view}
																qty={
																	deck.deck_cards.filter(
																		(existing) =>
																			existing.card_id == deck_card.card_id
																	).length
																}
																className="grid"
																card={deck_card.card}
																setCards={setCards}
																ownedByUser={
																	(deck.user && user_id == deck.user.id) ||
																	deck.access_key == accessKey
																}
																authToken={auth_token}
																deckID={deck.id}
																fetchDeck={fetchDeck}
																setDeckView={setDeckView}
																setPokemonCards={setPokemonCards}
																setEnergyCards={setEnergyCards}
																setTrainerCards={setTrainerCards}
																setPreview={setPreview}
																lockQty
																centerQty
															/>
														)
												)}
												{energyCards.map(
													(deck_card, index) =>
														energyCards[index + 1]?.card.id !==
															deck_card.card.id && (
															<DeckCard
																id={deck_card.id}
																view={view}
																qty={
																	deck.deck_cards.filter(
																		(existing) =>
																			existing.card_id == deck_card.card_id
																	).length
																}
																className="grid"
																card={deck_card.card}
																setCards={setCards}
																ownedByUser={
																	(deck.user && user_id == deck.user.id) ||
																	deck.access_key == accessKey
																}
																authToken={auth_token}
																deckID={deck.id}
																fetchDeck={fetchDeck}
																setDeckView={setDeckView}
																setPokemonCards={setPokemonCards}
																setEnergyCards={setEnergyCards}
																setTrainerCards={setTrainerCards}
																setPreview={setPreview}
																lockQty
																centerQty
															/>
														)
												)}
												{trainerCards.map(
													(deck_card, index) =>
														trainerCards[index + 1]?.card.id !==
															deck_card.card.id && (
															<DeckCard
																id={deck_card.id}
																view={view}
																qty={
																	deck.deck_cards.filter(
																		(existing) =>
																			existing.card_id == deck_card.card_id
																	).length
																}
																className="grid"
																card={deck_card.card}
																setCards={setCards}
																ownedByUser={
																	(deck.user && user_id == deck.user.id) ||
																	deck.access_key == accessKey
																}
																authToken={auth_token}
																deckID={deck.id}
																fetchDeck={fetchDeck}
																setDeckView={setDeckView}
																setPokemonCards={setPokemonCards}
																setEnergyCards={setEnergyCards}
																setTrainerCards={setTrainerCards}
																setPreview={setPreview}
																lockQty
																centerQty
															/>
														)
												)}
											</div>
										</div>
									</Col>
									<Col className="col-lg-6 col-12">
										<div className="deck-compare-side compare-user full border rounded p-10">
											<div
												className="compare-back full flex-row-left"
												onClick={() => setCompareDeck(null)}
											>
												{compareDeck && (
													<FontAwesomeIcon
														icon={faArrowLeft}
														className="default-text me-2"
													/>
												)}
												<span className="bold-text text-lg default-text">
													{compareDeck ? compareDeck.name : "Choose your deck"}
												</span>
											</div>
											{compareLoading ? (
												<div className="flex-column mt-5">
													<Spinner className="compare-loader" />
												</div>
											) : compareDeck ? (
												<div className="p-30">
													{comparePokemonCards.length !== 0 &&
													compareEnergyCards.length !== 0 &&
													compareTrainerCards.length !== 0 ? (
														<>
															{comparePokemonCards.map(
																(deck_card, index) =>
																	comparePokemonCards[index + 1]?.card.id !==
																		deck_card.card.id && (
																		<DeckCard
																			id={deck_card.id}
																			view={view}
																			qty={
																				comparePokemonCards.filter(
																					(existing) =>
																						existing.card_id ==
																						deck_card.card_id
																				).length
																			}
																			className="grid"
																			card={deck_card.card}
																			setCards={setCards}
																			ownedByUser={
																				(deck.user &&
																					user_id == deck.user.id) ||
																				deck.access_key == accessKey
																			}
																			authToken={auth_token}
																			deckID={deck.id}
																			fetchDeck={fetchDeck}
																			setDeckView={setDeckView}
																			setPokemonCards={setPokemonCards}
																			setEnergyCards={setEnergyCards}
																			setTrainerCards={setTrainerCards}
																			setPreview={setPreview}
																			lockQty
																			centerQty
																		/>
																	)
															)}
															{compareEnergyCards.map(
																(deck_card, index) =>
																	compareEnergyCards[index + 1]?.card.id !==
																		deck_card.card.id && (
																		<DeckCard
																			id={deck_card.id}
																			view={view}
																			qty={
																				compareEnergyCards.filter(
																					(existing) =>
																						existing.card_id ==
																						deck_card.card_id
																				).length
																			}
																			className="grid"
																			card={deck_card.card}
																			setCards={setCards}
																			ownedByUser={
																				(deck.user &&
																					user_id == deck.user.id) ||
																				deck.access_key == accessKey
																			}
																			authToken={auth_token}
																			deckID={deck.id}
																			fetchDeck={fetchDeck}
																			setDeckView={setDeckView}
																			setPokemonCards={setPokemonCards}
																			setEnergyCards={setEnergyCards}
																			setTrainerCards={setTrainerCards}
																			setPreview={setPreview}
																			lockQty
																			centerQty
																		/>
																	)
															)}
															{compareTrainerCards.map(
																(deck_card, index) =>
																	compareTrainerCards[index + 1]?.card.id !==
																		deck_card.card.id && (
																		<DeckCard
																			id={deck_card.id}
																			view={view}
																			qty={
																				compareTrainerCards.filter(
																					(existing) =>
																						existing.card_id ==
																						deck_card.card_id
																				).length
																			}
																			className="grid"
																			card={deck_card.card}
																			setCards={setCards}
																			ownedByUser={
																				(deck.user &&
																					user_id == deck.user.id) ||
																				deck.access_key == accessKey
																			}
																			authToken={auth_token}
																			deckID={deck.id}
																			fetchDeck={fetchDeck}
																			setDeckView={setDeckView}
																			setPokemonCards={setPokemonCards}
																			setEnergyCards={setEnergyCards}
																			setTrainerCards={setTrainerCards}
																			setPreview={setPreview}
																			lockQty
																			centerQty
																		/>
																	)
															)}
														</>
													) : (
														<div className="mt-4 flex-column full">
															<span>Empty Deck</span>
														</div>
													)}
												</div>
											) : (
												<div className="pt-30">
													{userDecks.map((userDeck) => (
														<div
															className="compare-deck"
															onClick={() => {
																setCompareDeck(userDeck);
																getUserDeckCards(userDeck);
															}}
														>
															<span className="medium-text default-text">
																{userDeck.name}
															</span>
														</div>
													))}
												</div>
											)}
										</div>
									</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								<Button
									type="button"
									variant="outline-secondary"
									className="rounded upper medium-text"
									onClick={closeCompareModal}
								>
									Close
								</Button>
							</Modal.Footer>
						</Modal>
					</div>
					<div
						ref={commentSectionRef}
						className="comments-section background-light"
					>
						<Container className="flex-row-center">
							<div className="col-lg-10 col-12">
								<Row>
									<Col className="col-lg-8 col-12">
										<div className="comments-container background-white rounded">
											<h2 className="comment-block text-xl bold-text default-text mb-0">
												Comments
											</h2>
											<Comments
												comments={deckComments}
												onComment={addComment}
												onReply={addReply}
												user={user}
												commentLoading={commentLoading}
												replyLoading={replyLoading}
												loginRedirect={`/decks/${deck.id}`}
												comment={comment}
												setComment={setComment}
												setCommentPlain={setCommentPlain}
												setMentions={setMentions}
												replyContent={replyContent}
												setReplyContent={setReplyContent}
												setReplyContentPlain={setReplyContentPlain}
												setReplyMentions={setReplyMentions}
												parentId={parentId}
												setParentId={setParentId}
											/>
										</div>
									</Col>
									<Col className="col-lg-4 col-12">
										<div className="tags-container background-white rounded">
											<h2 className="tag-block text-xl bold-text default-text">
												Tags
											</h2>
										</div>
									</Col>
								</Row>
							</div>
						</Container>
					</div>
				</>
			)}
		</>
	);
};

export default Deck;
